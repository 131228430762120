import axios from 'axios';

// Used to make authenticated HTTP requests to Django
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
export const API = axios.create({
    headers: {
        credentials: 'same-origin',
    },
});

/**
 * Given a 400 handle message, returns an async function that will, given a
 * url, return its parsed data, if there are no errors. If there are any API
 * errors, it will throw. If the API response is not JSON, it will throw. The
 * thrown error will have a `subheading` field, to be used in the UI messaging.
 *
 * @param {string} handle400Message
 */
const getWithErrorHandling = (handle400Message = null) => async url => {
    const { data, headers } = await API.get(url).catch(async ({ response }) => {
        // In case of error, add relevant data to the error object
        // so the right message can be shown to the user.
        const err = new Error();
        const status = response?.status;
        err.subheading =
            status && status < 500 && handle400Message
                ? handle400Message
                : 'Something went wrong';

        err.status = status;
        err.info = await response?.data;
        throw err;
    });

    // If response is not JSON, this is likely because it was made to a bad
    // URL, but instead of a server error, the response is the full
    // application, delivered by Django SPA. Report this as an error.
    if (headers?.['content-type'] !== 'application/json') {
        const err = new Error();
        err.subheading = 'Malformed URL';
        err.info = { url };
        throw err;
    }

    return data;
};

export const getTanks = getWithErrorHandling();
export const getTank = getWithErrorHandling(
    "If this tank exists you don't have access to it"
);

export const swrConfig = {
    onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404 || error.status === 403) return;

        // Only retry up to 5 times.
        if (retryCount >= 5) return false;

        // Retry after 1 second.
        setTimeout(() => revalidate({ retryCount }), 5000);
    },
};
