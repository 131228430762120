import querystring from 'querystring';
import { createAction } from 'redux-act';

import { removeEmpty } from '../utils';

export const SetQuery = createAction('Set query');
export const SetOpCenterFilter = createAction('Set operating center filter');
export const SetFlightDatesFilter = createAction('Set flight dates filter');
export const SetPressureZoneFilter = createAction('Set pressure zone filter');
export const SetPwsidFilter = createAction('Set pwsid filter');
export const SetConditionFilter = createAction('Set condition filter');
export const ResetFilters = createAction('Reset filters');
export const UpdateAllFilters = createAction('Update all filters');

const createQueryStringFromSearchFilters = filters => {
    return querystring.stringify(removeEmpty(filters));
};

export function updateURL({
    filters,
    history: {
        replace,
        location: { search },
    },
}) {
    const newQueryString = `?${createQueryStringFromSearchFilters(filters)}`;

    if (search === newQueryString) {
        return null;
    }

    if (!search && newQueryString.length === 1) {
        return null;
    }

    return replace(newQueryString);
}

const createFiltersFromQueryString = qs => {
    const qsToParse = qs.startsWith('?') ? qs.slice(1) : qs;

    const {
        query = '',
        opCenter = '',
        flightDates = null,
        pressureZone = '',
        pwsid = '',
        condition = '',
    } = querystring.parse(qsToParse);

    return {
        query,
        opCenter,
        flightDates,
        pressureZone,
        pwsid,
        condition,
    };
};

export function SetFiltersFromQueryString(qs = '') {
    return (dispatch, getState) => {
        if (!qs) return null;

        const filters = createFiltersFromQueryString(qs);

        return dispatch(UpdateAllFilters(filters));
    };
}
