import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'focus-visible';
import 'mapbox-gl/dist/mapbox-gl.css';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import App from './App';
import { Spinner } from 'theme-ui';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

const { store, persistor } = configureStore();

const sx = {
    spinner: {
        color: '#fff',
        size: '3.2rem',
        strokeWidth: 3,
    },
};

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={<Spinner sx={sx.spinner} />}
            persistor={persistor}
        >
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
