import { createAction } from 'redux-act';
import { API } from '../api';
import { URLS } from '../constants';

export const AttemptLogin = createAction('Attempt login');
export const SetUser = createAction('Login successful');
export const SetLoginError = createAction('Login error');
export const SetLoggedOut = createAction('Set logged out');
export const AttemptLogout = createAction('Attempt logout');
export const AttemptRegister = createAction('Attempt to register');
export const SetFormErrors = createAction('Set auth form errors');
export const RegisterSuccess = createAction('Registration successful');
export const AttemptResetPassword = createAction('Attempt to reset password');
export const ResetPasswordSuccess = createAction('Reset password successful');
export const AttemptConfirmReset = createAction(
    'Attempt confirm reset password'
);
export const ConfirmResetSuccess = createAction(
    'Confirm reset password successful'
);

export const Login = ({ email, password }) => async dispatch => {
    dispatch(AttemptLogin());
    try {
        const { data } = await API.post(URLS.login(), {
            email,
            password,
        });
        dispatch(SetUser(data));
    } catch (e) {
        const message = e.response?.data?.detail || 'Authorization error.';
        dispatch(SetLoginError(message));
    }
};

export const FetchProfile = () => async dispatch => {
    dispatch(AttemptLogin());
    try {
        const { data } = await API.get(URLS.login());
        dispatch(SetUser(data));
    } catch (e) {
        dispatch(SetLoggedOut());
    }
};

export const Logout = () => async dispatch => {
    dispatch(AttemptLogout());
    try {
        await API.post(URLS.logout());
        dispatch(SetLoggedOut());
    } catch (e) {
        const message = e.response?.data?.detail || 'Authorization error.';
        dispatch(SetLoginError(message));
    }
};

export const Register = ({ email, password, name }) => async dispatch => {
    dispatch(AttemptRegister());
    try {
        await API.post(URLS.register(), {
            email,
            password,
            first_name: name,
        });
        dispatch(RegisterSuccess(email));
    } catch (e) {
        const errors = e.response?.data;
        dispatch(SetFormErrors(errors));
    }
};

export const ResetPassword = ({ email }) => async dispatch => {
    dispatch(AttemptRegister());
    try {
        await API.post(URLS.resetPassword(), { email });
        dispatch(ResetPasswordSuccess(email));
    } catch (e) {
        const errors = e.response?.data;
        dispatch(SetFormErrors(errors));
    }
};

export const ConfirmResetPassword = ({
    uid,
    token,
    newPassword,
    newPasswordConfirmation,
}) => async dispatch => {
    dispatch(AttemptConfirmReset());

    if (!uid || !token) {
        return dispatch(
            SetFormErrors({
                details: 'An error prevented resetting your password',
            })
        );
    }

    if (newPassword !== newPasswordConfirmation) {
        return dispatch(
            SetFormErrors({
                password: "Password and confirmation don't match",
            })
        );
    }

    try {
        await API.post(URLS.confirmResetPassword(), {
            uid,
            token,
            new_password1: newPassword,
            new_password2: newPasswordConfirmation,
        });
        dispatch(ConfirmResetSuccess());
    } catch (e) {
        const errors = e.response?.data;
        dispatch(SetFormErrors(errors));
    }
};
