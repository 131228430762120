/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text, Link, IconButton } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt as faDownload } from '@fortawesome/free-solid-svg-icons';

import MapcallWarning from '../MapcallWarning';
import { URLS } from '../../constants';
import { toWorkorderUrl } from '../../utils';
import { ReactComponent as SiteScan } from '../../img/SiteScan.svg';

const sx = {
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '0.4rem',
        fontSize: 2,
    },
    workorder: {
        flex: 'none',
        color: 'textGray',
    },
    panorama: {
        alignItems: 'center',
    },
    link: {
        color: 'inherit',
        textDecoration: 'underline',
    },
    siteScanIcon: {
        width: '1.75rem',
        height: '1.75rem',
        mr: 1,
    },
    download: {
        flex: 'none',
        width: '2.4rem',
        height: '2.4rem',
        color: 'iconSecondary',
        fontSize: 3,
    },
};

function FlightFooter({ ST, tank, flight, ...props }) {
    return (
        <Flex sx={sx.footer} {...props}>
            <Text sx={sx.workorder}>
                {flight.workorder_id && (
                    <MapcallWarning leftAlign>
                        WO #
                        <Link
                            sx={sx.link}
                            href={toWorkorderUrl(flight)}
                            target='_blank'
                        >
                            {flight.workorder_id}
                        </Link>
                    </MapcallWarning>
                )}
            </Text>
            {flight.pano_url && (
                <Flex sx={sx.panorama}>
                    <SiteScan sx={sx.siteScanIcon} />
                    <Text sx={sx.workorder}>
                        <Link
                            sx={sx.link}
                            href={flight.pano_url}
                            target='_blank'
                        >
                            Panoramic view
                        </Link>
                    </Text>
                </Flex>
            )}
            <Link
                href={`${URLS.flight(ST, tank.id, flight.flight_date)}export/`}
                target='_blank'
            >
                <IconButton
                    sx={sx.download}
                    title='Download all images from this flight'
                >
                    <Icon icon={faDownload} />
                </IconButton>
            </Link>
        </Flex>
    );
}

export default FlightFooter;
