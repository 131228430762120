/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, IconButton } from 'theme-ui';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { getSuggestUrl, getGeocodeUrl } from '../utils';
import { SetMapSearchPoint } from '../redux/map.actions';

const sx = {
    container: {
        position: 'absolute',
        right: '1.6rem',
        borderRadius: '2px',
        ':not(:empty)': {
            boxShadow: 1,
        },
        top: '1.6rem',
        width: '300px',
    },
    searchIcon: {
        position: 'absolute',
        fontSize: '1.6rem',
        color: 'iconGray',
        alignSelf: 'center',
        left: '1rem',
    },
    searchControl: {
        position: 'absolute',
        right: '1.6rem',
        ':not(:empty)': {
            boxShadow: 1,
        },
        top: '1.6rem',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '2px',
        boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2)',
        width: 40,
        height: 40,
    },
    searchControlIconButton: {
        width: '100% !important',
        height: '100% !important',
    },
};

const iconWidth = '2.5rem';
const customStyles = {
    input: (provided, state) => ({
        ...provided,
        paddingLeft: iconWidth,
        fontSize: '1.6rem',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        paddingLeft: iconWidth,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        paddingLeft: iconWidth,
    }),
};

const DropdownIndicator = () => <Icon icon={faSearch} sx={sx.searchIcon} />;

export default function LocationSearch({ setErrorMessage }) {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [showSearch, setShowSearch] = useState(false);

    const handleBlur = () => setShowSearch(false);

    const handleInputChange = value => setSearchText(value);

    const handleSearch = async (value, callback) => {
        try {
            const req = await axios.get(getSuggestUrl(value));
            if (req.status !== 200) {
                throw Error(`Error from suggestion service: ${req.status}`);
            }
            callback(
                req.data?.suggestions?.map(suggestion => ({
                    value: suggestion.magicKey,
                    label: suggestion.text,
                })) || []
            );
        } catch (error) {
            callback([]);
            console.error('Error suggesting location search', error);
        }
    };

    const handleSelect = async (payload, { action }) => {
        if (action === 'clear') {
            setErrorMessage(false);
            return;
        }
        try {
            const { value } = payload;
            const req = await axios.get(getGeocodeUrl(searchText, value));
            if (req.status !== 200 || !req.data?.candidates?.length) {
                throw Error('No candidates returned');
            } else {
                const { location } = req.data.candidates[0];
                const searchPoint = {
                    latitude: location.y,
                    longitude: location.x,
                };
                dispatch(SetMapSearchPoint(searchPoint));
            }
        } catch (error) {
            console.error('Address candidate error:', error);
            setErrorMessage('Sorry, that location could not be used.');
        }
    };

    if (!showSearch) {
        return (
            <div
                className='mapboxgl-ctrl mapboxgl-ctrl-group'
                sx={sx.searchControl}
                title='Location Search'
            >
                <IconButton
                    className='mapboxgl-ctrl-icon'
                    sx={sx.searchControlIconButton}
                    onClick={() => setShowSearch(true)}
                    aria-label='Location Search'
                >
                    <Icon size='lg' icon={faSearch} />
                </IconButton>
            </div>
        );
    }

    return (
        <div sx={sx.container}>
            <AsyncSelect
                loadOptions={handleSearch}
                defaultOptions
                onInputChange={handleInputChange}
                onChange={handleSelect}
                isClearable
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                }}
                placeholder='Location'
                onBlur={handleBlur}
                autoFocus
                styles={customStyles}
                openMenuOnFocus={false}
            />
        </div>
    );
}
