import produce from 'immer';
import { createReducer } from 'redux-act';
import { set } from '../utils';

import {
    SetSTList,
    SetST,
    SetSTDetail,
    FetchSTDetail,
    SetSTError,
} from './st.actions';

export const initialState = {
    // only use to capture transient ST information, e.g. save route ST before
    // redirecting unauthenticated user to /login
    ST: null,
    STList: [],
    // canonical reference for ST information
    STDetail: {
        fetching: false,
        error: null,
        data: null,
    },
};

export default createReducer(
    {
        [SetSTList]: set('STList'),
        [SetST]: set('ST'),
        [SetSTDetail]: produce((state, payload) => {
            state.STDetail.fetching = false;
            state.STDetail.error = null;
            state.STDetail.data = payload;
        }),
        [FetchSTDetail]: produce(state => {
            state.STDetail.fetching = true;
            state.STDetail.error = null;
            state.STDetail.data = null;
        }),
        [SetSTError]: produce((state, payload) => {
            state.STDetail.fetching = false;
            state.STDetail.error = payload;
            state.STDetail.data = null;
        }),
    },
    initialState
);
