/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState, useMemo } from 'react';
import { jsx, Box, Flex, Input, Select, Button } from 'theme-ui';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import FlightDatePicker from './FlightDatePicker';
import { applyTankFilters, unique, getPressureZone } from '../utils';
import { CONDITIONS, NULL_UI_VALUE } from '../constants';
import {
    SetQuery,
    SetOpCenterFilter,
    SetFlightDatesFilter,
    SetPressureZoneFilter,
    SetPwsidFilter,
    SetConditionFilter,
    ResetFilters,
    SetFiltersFromQueryString,
    updateURL,
} from '../redux/filters.actions';

const sx = {
    filterBar: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        bg: 'backgroundGray',
        height: '6.4rem',
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    filters: {
        position: 'relative',
        flex: 'none',
        minWidth: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        bg: 'backgroundGray',
        px: 2,
    },
    searchBox: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        flex: 'auto',
        width: 'auto',
        minWidth: '24rem',
        maxWidth: '40rem',
    },
    searchInput: {
        py: 1,
        pl: 2,
        pr: 1,
        width: '100%',
        fontSize: '1.6rem',
    },
    select: {
        flex: 'none',
        ml: 2,
        py: 1,
        pr: 4,
        width: 'auto',
        minWidth: '16rem',
        fontSize: '1.6rem',
        textTransform: 'capitalize',
    },
    option: {
        textTransform: 'capitalize',
    },
    dates: {
        flex: 'none',
        ml: 2,
        width: 'auto',
        minWidth: '16rem',
        fontSize: '1.6rem',
    },
    reset: {
        flex: 'none',
        ml: 2,
        py: 1,
        pr: 4,
        width: 'auto',
        fontSize: '1.6rem',
    },
};

const WAIT_FOR_TYPING_TO_END_MS = 300;

function FilterBar({
    query,
    onQueryChange,
    opCenter,
    onOpCenterChange,
    flightDates,
    onFlightDatesChange,
    pressureZone,
    onPressureZoneChange,
    pwsid,
    onPwsidChange,
    condition,
    onConditionChange,
    tanks,
    onReset,
    hydrateFilters,
    ...props
}) {
    const history = useHistory();
    const [isHydrated, setIsHydrated] = useState(false);
    const [localQuery, setLocalQuery] = useState(query);

    // When the page loads, if the filter state hasn't been updated,
    // set filters from queries.
    const {
        location: { search },
    } = history;
    useEffect(() => {
        if (!isHydrated) {
            setIsHydrated(true);
            hydrateFilters(search);
        }
    }, [isHydrated, hydrateFilters, search]);

    // When a filter changes, update the URL search string
    useEffect(() => {
        updateURL({
            history,
            filters: {
                query,
                opCenter,
                flightDates,
                pressureZone,
                pwsid,
                condition,
            },
        });
    }, [
        query,
        opCenter,
        flightDates,
        pressureZone,
        pwsid,
        condition,
        history,
        history.location.pathname,
    ]);

    const opCenters = useMemo(() => {
        return tanks ? unique(tanks.map(t => t.operatingcenter)).sort() : [];
    }, [tanks]);

    // Pressure Zone values should be limited to the list of tanks
    // available by applying all the other filters except Pressure Zone
    const pressureZoneFilteredTanks = applyTankFilters(tanks, {
        query,
        pwsid,
        opCenter,
        flightDates,
        condition,
    });
    const pressureZones = useMemo(() => {
        return pressureZoneFilteredTanks
            ? unique(pressureZoneFilteredTanks.map(getPressureZone)).sort()
            : [];
    }, [pressureZoneFilteredTanks]);

    // PWSID values should be limited to the list of tanks
    // available by applying all the other filters except PWSID
    const pwsidFilteredTanks = applyTankFilters(tanks, {
        query,
        opCenter,
        flightDates,
        pressureZone,
        condition,
    });

    const pwsids = useMemo(() => {
        return pwsidFilteredTanks
            ? unique(pwsidFilteredTanks.map(t => t.pwsid)).sort()
            : [];
    }, [pwsidFilteredTanks]);

    // Clear PressureZone filter if its value is stale
    useEffect(() => {
        if (
            !!pressureZone &&
            !!pressureZoneFilteredTanks &&
            !pressureZoneFilteredTanks.find(
                t => getPressureZone(t) === pressureZone
            )
        ) {
            onPressureZoneChange();
        }
    }, [pressureZone, pressureZoneFilteredTanks, onPressureZoneChange]);

    //Clear PWSID filter if its value is stale
    useEffect(() => {
        if (
            !!pwsid &&
            !!pwsidFilteredTanks &&
            !pwsidFilteredTanks.find(t =>
                pwsid === NULL_UI_VALUE ? !t.pwsid : pwsid === t.pwsid
            )
        ) {
            onPwsidChange();
        }
    }, [pwsid, pwsidFilteredTanks, onPwsidChange]);

    // Debounce saving query value to the filter
    useDebounce(() => onQueryChange(localQuery), WAIT_FOR_TYPING_TO_END_MS, [
        localQuery,
    ]);

    // Always update localQuery with canonical value
    useEffect(() => {
        setLocalQuery(query);
    }, [query, setLocalQuery]);

    return (
        <Flex sx={sx.filterBar} {...props}>
            <Flex sx={sx.filters}>
                <Box sx={sx.searchBox}>
                    <Input
                        type='search'
                        sx={sx.searchInput}
                        value={localQuery}
                        onChange={e => setLocalQuery(e.target.value)}
                        placeholder='Tank Name'
                    />
                </Box>
                <Select
                    sx={sx.select}
                    value={opCenter}
                    onChange={onOpCenterChange}
                >
                    <option value=''>Any operating center</option>
                    {opCenters.map(center => {
                        const value = center || NULL_UI_VALUE;
                        return (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        );
                    })}
                </Select>
                <FlightDatePicker
                    sx={sx.dates}
                    allowRange
                    value={flightDates}
                    onChange={onFlightDatesChange}
                    placeholder='Any flight dates'
                />
                <Select
                    sx={sx.select}
                    value={pressureZone}
                    onChange={onPressureZoneChange}
                >
                    <option value=''>Any pressure zone</option>
                    {pressureZones.map(zone => (
                        <option key={zone} value={zone}>
                            {zone}
                        </option>
                    ))}
                </Select>
                <Select sx={sx.select} value={pwsid} onChange={onPwsidChange}>
                    <option value={''}>Any PWSID</option>
                    {pwsids.map(id => {
                        const value = id || NULL_UI_VALUE;
                        return (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        );
                    })}
                </Select>
                <Select
                    sx={sx.select}
                    value={condition}
                    onChange={onConditionChange}
                >
                    <option sx={sx.option} value=''>
                        Any condition
                    </option>
                    {CONDITIONS.map(c => (
                        <option sx={sx.option} key={c} value={c}>
                            {c}
                        </option>
                    ))}
                </Select>
                {/* Hide the reset button when no filters active */}
                <Button sx={sx.reset} onClick={onReset}>
                    Reset filters
                </Button>
            </Flex>
        </Flex>
    );
}

const mapStateToProps = ({ filters }) => filters;

const mapDispatchToProps = dispatch => {
    return {
        onQueryChange: q => dispatch(SetQuery(q)),
        onOpCenterChange: e => dispatch(SetOpCenterFilter(e.target.value)),
        onFlightDatesChange: dates => dispatch(SetFlightDatesFilter(dates)),
        onPressureZoneChange: e =>
            dispatch(SetPressureZoneFilter(e?.target.value || '')),
        onPwsidChange: e => dispatch(SetPwsidFilter(e?.target.value || '')),
        onConditionChange: e => dispatch(SetConditionFilter(e.target.value)),
        onReset: () => dispatch(ResetFilters()),
        hydrateFilters: qs => dispatch(SetFiltersFromQueryString(qs)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
