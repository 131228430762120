import { createAction } from 'redux-act';

export const SetViewport = createAction('Set viewport');
export const SetMapSearchPoint = createAction('Set map search point');
export const AllowAutoZoom = createAction(
    'Allow map to auto zoom on filter changes'
);
export const ForbidAutoZoom = createAction(
    'Forbid map from auto zooming on filter changes'
);
