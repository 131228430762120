/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex, Text, Link } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt as faExternalLink } from '@fortawesome/free-solid-svg-icons';

import MapcallWarning from './MapcallWarning';
import { toMapcallUrl, flightToDateString, getPressureZone } from '../utils';

const sx = {
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 2,
        px: 2,
        py: 1,
        minHeight: '4.8rem',
        borderWidth: '1px 0 0 0',
        borderStyle: 'solid',
        borderColor: 'backgroundGray',
    },
    label: {
        width: '10em',
        flex: 'none',
        mr: 1,
        fontWeight: 'bold',
    },
    value: {
        flex: 'auto',
    },
    link: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'primary',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
    linkIcon: {
        ml: 1,
        fontSize: 1,
    },
};

function DetailItem({ label, value, href, ...props }) {
    if (href) {
        value = (
            <MapcallWarning>
                <Link sx={sx.link} href={href} target='_blank' title={value}>
                    {value}
                    <Icon icon={faExternalLink} sx={sx.linkIcon} />
                </Link>
            </MapcallWarning>
        );
    }

    return (
        <Flex sx={sx.item} {...props}>
            <Text sx={sx.label}>{label}</Text>
            <Text sx={sx.value}>{value}</Text>
        </Flex>
    );
}

function DetailFacts({ tank, ...props }) {
    const lastFlight =
        tank.flights.length > 0 && tank.flights[tank.flights.length - 1];

    return (
        <Box sx={sx.facts} {...props}>
            <DetailItem label='Operating Center' value={tank.operatingcenter} />
            <DetailItem
                label='Mapcall'
                value={tank.mapcall_id || 'None'}
                href={tank.mapcall_id && toMapcallUrl(tank)}
            />
            <DetailItem label='Pressure Zone' value={getPressureZone(tank)} />
            <DetailItem label='PWSID' value={tank.pwsid} />
            <DetailItem
                label='Last flight'
                value={flightToDateString(lastFlight) || 'None'}
            />
        </Box>
    );
}

export default DetailFacts;
