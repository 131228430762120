/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Heading, Text } from 'theme-ui';
import { Link } from 'react-router-dom';

const sx = {
    page: {
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    appBar: {
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        bg: 'primary',
        px: 3,
        height: '6.4rem',
    },
    title: {
        flex: 'none',
        fontSize: 6,
        lineHeight: 1,
        fontWeight: 'normal',
        color: '#fff',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    contents: {
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    heading: {
        p: 4,
    },
    subheading: {
        p: 8,
        fontSize: 4,
    },
    link: {
        pt: 8,
        width: '149px',
        height: '21px',
        color: '#00517f',
        fontSize: 4,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecoration: 'none',
    },
};

function FourOhFourPage({
    heading = 'Nothing to see here',
    subheading = 'There is nothing at this URL',
}) {
    return (
        <Flex sx={sx.page}>
            <Flex as='header' sx={sx.appBar}>
                <Heading as='h2' sx={sx.title}>
                    No Tanks
                </Heading>
            </Flex>
            <Flex sx={sx.contents}>
                <Heading sx={sx.heading} as='h1'>
                    {heading}
                </Heading>
                <Text sx={sx.subheading}>{subheading}</Text>
                <Link sx={sx.link} to='/'>
                    Search for tanks
                </Link>
            </Flex>
        </Flex>
    );
}

export default FourOhFourPage;
