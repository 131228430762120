/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Grid } from 'theme-ui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useSWR from 'swr';

import { ORBITS, URLS } from '../constants';
import { getTank, swrConfig } from '../api';
import { FetchProfile } from '../redux/auth.actions';
import PageLayout from '../components/PageLayout';
import DetailMap from '../components/DetailMap';
import PhotoViewer from '../components/PhotoViewer';
import AddFirstFlightButton from '../components/AddFirstFlightButton';
import DetailChart from '../components/DetailChart';
import DetailFlight from '../components/DetailFlight';
import DetailFacts from '../components/DetailFacts';
import FourOhFourPage from './404';
import { isPilotForST, logError } from '../utils';

const sx = {
    main: {
        flex: 'auto',
        height: '100%',
        gridTemplateColumns: '40rem 1fr',
        gridTemplateRows: '28rem minmax(0, 1fr)',
        gridTemplateAreas: '"sidebar map" "sidebar photo"',
        rowGap: '1px',
        minHeight: 0,
    },
    sidebar: {
        gridArea: 'sidebar',
        flexDirection: 'column',
        alignItems: 'stretch',
        bg: 'background',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
    },
    addFlight: {
        alignSelf: 'center',
        my: 6,
        boxShadow: 3,
    },
    chart: {
        flex: 'none',
    },
    flight: {
        flex: 'none',
        mb: 4,
    },
    facts: {
        mt: 'auto',
    },
    map: {
        gridArea: 'map',
        bg: 'secondary',
        zIndex: 0,
    },
    photo: {
        gridArea: 'photo',
        bg: 'secondary',
    },
};

function DetailPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { ST, tankId, flightDate } = useParams();
    const { data, mutate, error } = useSWR(
        URLS.tank(ST, tankId),
        getTank,
        swrConfig
    );
    const flight =
        flightDate && data?.flights?.find(f => f.flight_date === flightDate);

    const user = useSelector(state => state.auth.user);

    // Redirect to most recent flight
    // if the tank has any flights and
    // if no flight is specified or
    // if the specified flight is not found
    useEffect(() => {
        if (data?.flights?.length > 0) {
            const lastFlightDate =
                data.flights[data.flights.length - 1].flight_date;

            if (!flightDate || !flight) {
                history.replace(
                    `/${ST}/tank/${tankId}/flight/${lastFlightDate}`,
                    history.location.state
                );
            }
        }
    }, [history, data, ST, tankId, flightDate, flight]);

    const [orbit, setOrbit] = useState(ORBITS.HIGH);
    const [image, setImage] = useState('nadir');

    if (error) {
        if (error.status === 403) {
            dispatch(FetchProfile());
        } else {
            logError(error);
        }

        if (!data) {
            return <FourOhFourPage subheading={error.subheading} />;
        }
    }

    const navToSearch = () => {
        const { from } = history.location.state || {
            from: { pathname: `/${ST}/` },
        };
        history.push(from);
    };

    const navToAddFlight = () => {
        history.push(`/${ST}/tank/${tankId}/add-flight`);
    };

    let imageUrl;
    if (flight && flight.flight_images) {
        if (image === 'nadir') {
            imageUrl = flight.flight_images.nadir?.url;
        } else {
            const [o, b] = image.split('_');
            imageUrl = flight.flight_images[o][b]?.url;
        }
    }

    return (
        <PageLayout
            isLoading={!data}
            mode='detail'
            title={data?.facilityname}
            onClose={navToSearch}
            onAddFlight={navToAddFlight}
            ST={ST}
            tank={data}
            flight={flight}
            onChange={mutate}
            email
            admin
        >
            {data && (
                <Grid gap={0} sx={sx.main}>
                    <Flex sx={sx.sidebar}>
                        {!flight && isPilotForST(user, ST) && (
                            <AddFirstFlightButton
                                sx={sx.addFlight}
                                onClick={navToAddFlight}
                            />
                        )}
                        {flight && (
                            <DetailChart
                                sx={sx.chart}
                                ST={ST}
                                tank={data}
                                selectedFlight={flight}
                            />
                        )}
                        <DetailFlight
                            sx={sx.flight}
                            ST={ST}
                            tank={data}
                            flight={flight}
                            orbit={orbit}
                            onOrbitChange={setOrbit}
                            image={image}
                            onImageChange={setImage}
                        />
                        <DetailFacts sx={sx.facts} tank={data} />
                    </Flex>
                    <DetailMap
                        sx={sx.map}
                        tank={data}
                        flight={flight}
                        orbit={orbit}
                        onOrbitChange={setOrbit}
                        image={image}
                        onImageChange={setImage}
                    />
                    <PhotoViewer
                        sx={sx.photo}
                        noFlights={data.flights?.length === 0}
                        photo={imageUrl}
                    />
                </Grid>
            )}
        </PageLayout>
    );
}

export default DetailPage;
