/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid, Button, AspectImage } from 'theme-ui';

import { BEARINGS } from '../../constants';

const sx = {
    grid: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gridTemplateAreas: "'nw n ne' 'w nadir e' 'sw s se'",
    },
    button: {
        borderRadius: 0,
        outlineWidth: '3px',
        outlineColor: 'accent',
        bg: 'transparent',
        p: 0,
        cursor: 'pointer',
    },
    image: {
        width: '100%',
        height: '100%',
    },
};

function Photo({
    src,
    orbit,
    bearing,
    selected,
    disabled,
    onSelect,
    ...props
}) {
    const positionSx = { gridArea: bearing };
    const selectedSx = selected ? { outlineStyle: 'solid' } : {};
    const disabledSx = disabled
        ? {
              bg: 'muted',
              outlineStyle: 'none',
              cursor: 'default',
              pb: '100%',
          }
        : {};

    return (
        <Button
            sx={{ ...sx.button, ...positionSx, ...selectedSx, ...disabledSx }}
            className='focus-visible'
            disabled={disabled}
            onClick={disabled ? null : onSelect}
            {...props}
        >
            {disabled || (
                <AspectImage
                    ratio={1}
                    src={src.thumbnail}
                    sx={sx.image}
                    alt={`${bearing.toUpperCase()} photo`}
                    crossOrigin='use-credentials'
                />
            )}
        </Button>
    );
}

function PhotoGrid({ flight, orbit, image, onSelect, disabled, ...props }) {
    return (
        <Grid gap={1} sx={sx.grid} {...props}>
            {BEARINGS.map(bearing => {
                if (disabled) {
                    return <Photo key={bearing} disabled />;
                }

                if (bearing === 'nadir') {
                    const source = flight.flight_images?.nadir;
                    return (
                        <Photo
                            key={bearing}
                            src={source}
                            orbit={orbit}
                            bearing={bearing}
                            selected={'nadir' === image}
                            disabled={!source?.url}
                            onSelect={() => onSelect('nadir')}
                        />
                    );
                }

                const imageName = `${orbit}_${bearing}`;
                const source =
                    flight.flight_images &&
                    flight.flight_images[orbit][bearing];

                return (
                    <Photo
                        key={bearing}
                        src={source}
                        orbit={orbit}
                        bearing={bearing}
                        selected={imageName === image}
                        disabled={!source?.url}
                        onSelect={() => onSelect(imageName)}
                    />
                );
            })}
        </Grid>
    );
}

export default PhotoGrid;
