/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex, Heading, Card, Textarea } from 'theme-ui';
import { Component } from 'react';
import { node } from 'prop-types';

import { ReactComponent as Logo } from '../img/Logo.svg';
import { APP_NAME } from '../constants';
import { logError } from '../utils';

const sx = {
    page: {
        bg: 'primary',
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    logo: {
        mb: 5,
        flex: 'none',
        width: '4.8rem',
        color: '#fff',
    },
    heading: {
        mb: 5,
        flex: 'none',
        color: '#fff',
        fontSize: 6,
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    card: {
        flex: 'none',
        bg: 'background',
        width: '40rem',
        boxShadow: 4,
    },
    cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '4.8rem',
        px: '4.8rem',
    },
    cardHeading: {
        m: 0,
        fontSize: 3,
        lineHeight: 1,
    },
    cardBack: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4.8rem',
        height: '4.8rem',
        fontSize: '1.6rem',
    },
    cardBody: {
        pt: 4,
        pb: 5,
        px: 5,
    },
    label: {
        mb: 1,
    },
    input: {
        mb: 4,
    },
    submit: {
        width: '100%',
        mt: 2,
    },
    modeButtons: {
        mt: 6,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '40rem',
    },
    modeButton: {
        flex: 'none',
        color: 'white',
    },
    errors: {
        color: 'severe',
    },
};

// Class-based component because `componentDidCatch`
// is not available in functional components
class ErrorBoundary extends Component {
    state = {};

    componentDidCatch(error) {
        this.setState({ error });

        logError(error);
    }

    // To decode this later, just run "atob(hashedStringHere)"
    encodeErrorMessage = error =>
        btoa(`${error.message} ::::::: ${error.stack}`);

    render() {
        if (this.state.error) {
            return (
                <Flex sx={sx.page}>
                    <Logo sx={sx.logo} />
                    <Heading as='h1' sx={sx.heading}>
                        {APP_NAME}
                    </Heading>
                    <Card sx={sx.card}>
                        <Flex as='header' sx={sx.cardHeader}>
                            <Heading
                                as='h2'
                                sx={{ ...sx.cardHeading, ...sx.errors }}
                            >
                                Something Went Wrong
                            </Heading>
                        </Flex>
                        <Box sx={sx.cardBody}>
                            <p>
                                Please refresh the page and try again. If the
                                problem persists, contact support and send along
                                the text below.
                            </p>
                            <Textarea
                                sx={sx.input}
                                rows={15}
                                value={this.encodeErrorMessage(
                                    this.state.error
                                )}
                                disabled
                            />
                        </Box>
                    </Card>
                </Flex>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: node.isRequired,
};

export default ErrorBoundary;
