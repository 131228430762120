/** @jsxRuntime classic */
/** @jsx jsx */
import { memo } from 'react';
import { jsx, Box, useThemeUI } from 'theme-ui';
import {
    VictoryChart,
    VictoryAxis,
    VictoryLine,
    VictoryScatter,
} from 'victory';

import { CONDITION_COLORS } from '../constants';
import { getFlightCondition, getXAxis, getYAxis } from '../utils';

function SearchSparkline({ tank, showAll, ...props }) {
    const { theme } = useThemeUI();

    const dataLastFlight =
        tank.flights.length > 0 ? [tank.flights[tank.flights.length - 1]] : [];

    return (
        <Box {...props}>
            <VictoryChart
                domain={{ y: [0, 1] }}
                height={30}
                width={80}
                padding={8}
            >
                <VictoryAxis
                    style={{
                        axis: { stroke: 'transparent' },
                    }}
                />
                <VictoryLine
                    data={tank.flights}
                    style={{
                        data: { stroke: theme.colors.primary, strokeWidth: 1 },
                    }}
                    x={getXAxis}
                    y={getYAxis(tank)}
                />
                <VictoryScatter
                    data={showAll ? tank.flights : dataLastFlight}
                    x={getXAxis}
                    y={getYAxis(tank)}
                    size={3}
                    style={{
                        data: {
                            strokeWidth: 1,
                            stroke: () =>
                                showAll ? theme.colors.primaryTint : '#fff',
                            fill: ({ datum }) =>
                                CONDITION_COLORS[getFlightCondition(datum)],
                        },
                    }}
                />
            </VictoryChart>
        </Box>
    );
}

// The vector charts make this component expensive to rerender, so we memoize
// it to only rerender when the props change.
export default memo(SearchSparkline);
