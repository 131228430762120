/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import transformTranslate from '@turf/transform-translate';
import { getCoords } from '@turf/invariant';

import OrbitControl from './OrbitControl';
import CameraMarker from './CameraMarker';
import { BEARINGS, BEARING_ANGLES, ORBITS } from '../constants';
import MAP_STYLE from '../mapStyle.json';

const sx = {
    mapControl: {
        position: 'absolute',
        right: '1.6rem',
        borderRadius: '2px',
        ':not(:empty)': {
            boxShadow: 1,
        },
        '> button': {
            width: '4rem',
            height: '4rem',
        },
    },
    zoom: {
        top: '1.6rem',
    },
    orbit: {
        top: '12rem',
    },
};

function DetailMap({
    tank,
    flight,
    orbit = ORBITS.MID,
    onOrbitChange,
    image,
    onImageChange,
    ...props
}) {
    const [longitude, latitude] = tank.geom.coordinates;
    const [viewport, setViewport] = useState({
        longitude,
        latitude,
        zoom: 17,
    });

    const cameras = BEARINGS.filter(b => b !== 'nadir').map(bearing => {
        const [longitude, latitude] = getCoords(
            transformTranslate(tank.geom, 0.04, BEARING_ANGLES[bearing])
        );
        const imageName = `${orbit}_${bearing}`;
        const url =
            flight?.flight_images && flight.flight_images[orbit][bearing].url;

        return (
            <CameraMarker
                key={`camera-${bearing}`}
                bearing={bearing}
                latitude={latitude}
                longitude={longitude}
                disabled={!url}
                selected={imageName === image}
                onClick={() => onImageChange(imageName)}
            />
        );
    });

    const nadirSelected = image === 'nadir';
    const nadirAvailable = flight?.flight_images?.nadir?.url;

    return (
        <ReactMapGL
            {...viewport}
            width='100%'
            height='100%'
            mapStyle={MAP_STYLE}
            onViewportChange={nextViewport => setViewport(nextViewport)}
            dragRotate={false}
            {...props}
        >
            <Marker
                sx={{
                    fontSize: '24px', // TODO Should probably be zoom-dependent
                    color:
                        (!nadirAvailable && 'disabled') ||
                        (nadirSelected && 'accent') ||
                        'primary',
                    cursor: nadirAvailable && 'pointer !important',
                }}
                longitude={longitude}
                latitude={latitude}
                offsetTop={-12}
                offsetLeft={-12}
            >
                <Icon
                    onClick={() => nadirAvailable && onImageChange('nadir')}
                    icon={faDotCircle}
                />
            </Marker>
            {cameras}
            <NavigationControl
                sx={{ ...sx.mapControl, ...sx.zoom }}
                showCompass={false}
            />
            <OrbitControl
                sx={{ ...sx.mapControl, ...sx.orbit }}
                orbit={orbit}
                onChange={onOrbitChange}
                disabled={!flight}
            />
        </ReactMapGL>
    );
}

export default DetailMap;
