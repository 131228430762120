/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Marker } from 'react-map-gl';
import { MARKER_SHADOW } from '../constants';

function TankMarker({ condition = 'unknown', onClick, ...props }) {
    return (
        <Marker offsetLeft={-6} offsetTop={-6} {...props}>
            <svg
                width={12}
                height={12}
                viewBox='0 0 100 100'
                filter={MARKER_SHADOW}
                onClick={onClick}
            >
                <circle
                    cx='50'
                    cy='50'
                    r='46'
                    stroke='#fff'
                    strokeWidth='12'
                    sx={{ fill: condition, cursor: 'pointer' }}
                />
            </svg>
        </Marker>
    );
}

export default TankMarker;
