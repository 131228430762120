/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex, Button, Text } from 'theme-ui';
import { useState, memo } from 'react';
import DetailChart from './DetailChart';
import { CARD_SHADOW } from '../constants';
import { getPressureZone, flightToDateString } from '../utils';

const sx = {
    container: {
        position: 'relative',
        flexDirection: 'column',
        height: '12.5rem',
    },
    name: {
        flex: 'none',
        mb: 1,
        maxWidth: '100%',
        fontSize: 0,
        fontWeight: 'bold',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    card: {
        position: 'absolute',
        top: '100%',
        left: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        bg: 'background',
        p: 1,
        minWidth: '22rem',
        maxWidth: '28rem',
        borderLeft: '8px solid',
        borderLeftColor: 'unknown',
        filter: CARD_SHADOW,
    },
    cardName: {
        mb: 1,
        fontSize: 2,
        fontWeight: 'bold',
        zIndex: 1,
    },
    fact: {
        my: '0.2rem',
        fontSize: 0,
    },
    condition: {
        mt: 1,
        fontSize: 0,
        fontWeight: 'bold',
        '&::first-letter': {
            textTransform: 'capitalize',
        },
    },
    since: {
        color: 'textGray',
        fontWeight: 'normal',
    },
};

function TankCard({ tank, flight }) {
    const condition = flight.condition_override || flight.condition;
    return (
        <Box sx={{ ...sx.card, borderLeftColor: condition }}>
            <Text sx={sx.cardName}>{tank.facilityname}</Text>
            <Text sx={sx.fact}>{tank.operatingcenter}</Text>
            <Text sx={sx.fact}>{getPressureZone(tank)}</Text>
            <Text sx={sx.fact}>{tank.pwsid}</Text>
            <Text sx={sx.condition}>
                {condition} condition{' '}
                <span sx={sx.since}>as of {flightToDateString(flight)}</span>
            </Text>
        </Box>
    );
}

const noOp = () => {};

function SearchChart({ tank, onSelect = noOp, ST, ...props }) {
    const [flight, setFlight] = useState();
    const onSelectTank = () => onSelect(tank.id);

    return (
        <Flex sx={sx.container} {...props}>
            <Button
                variant='text'
                sx={sx.name}
                onClick={onSelectTank}
                title={tank.facilityname}
            >
                {tank.facilityname}
            </Button>
            {tank.flights?.length > 0 && (
                <DetailChart
                    ST={ST}
                    tank={tank}
                    selectedFlight={flight}
                    chartProps={{
                        domain: { y: [0, 1] },
                        height: 80,
                        width: 160,
                        padding: 8,
                    }}
                    axisProps={{
                        style: {
                            axis: { stroke: 'transparent' },
                        },
                        tickFormat: t => null,
                    }}
                    onMouseOver={setFlight}
                    renavigate
                />
            )}
            {flight && <TankCard flight={flight} tank={tank} />}
        </Flex>
    );
}

export default memo(SearchChart);
