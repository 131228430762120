import { createReducer } from 'redux-act';
import produce from 'immer';

import {
    AttemptLogin,
    SetLoginError,
    SetUser,
    SetLoggedOut,
    SetFormErrors,
    AttemptRegister,
    RegisterSuccess,
    AttemptResetPassword,
    ResetPasswordSuccess,
    AttemptConfirmReset,
    ConfirmResetSuccess,
} from './auth.actions';

export const initialState = {
    // null: we don't know the user's login status.
    // false: the user is logged out.
    user: null,
    loading: false,
    error: '',
    formErrors: {},
    successMessage: '',
};

export default createReducer(
    {
        [AttemptLogin]: produce((state, payload) => {
            state.loading = true;
            state.error = initialState.error;
            state.formErrors = initialState.formErrors;
            state.successMessage = initialState.successMessage;
        }),
        [SetUser]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.formErrors = initialState.formErrors;
            state.user = payload;
            state.successMessage = initialState.successMessage;
        }),
        [SetLoginError]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = payload;
            state.formErrors = initialState.formErrors;
            state.successMessage = initialState.successMessage;
        }),
        [SetLoggedOut]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.formErrors = initialState.formErrors;
            state.user = false;
            state.successMessage = initialState.successMessage;
        }),
        [SetFormErrors]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.formErrors = payload;
            state.user = false;
            state.successMessage = initialState.successMessage;
        }),
        [AttemptRegister]: produce((state, payload) => {
            state.loading = true;
            state.formErrors = initialState.payload;
            state.successMessage = initialState.successMessage;
        }),
        [RegisterSuccess]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.formErrors = initialState.payload;
            state.successMessage = `Success! A verification email has been sent to ${payload}.`;
        }),
        [AttemptResetPassword]: produce((state, payload) => {
            state.loading = true;
            state.formErrors = initialState.payload;
            state.successMessage = initialState.successMessage;
        }),
        [ResetPasswordSuccess]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.formErrors = initialState.payload;
            state.successMessage = `Success! A verification email has been sent to ${payload}.`;
        }),
        [AttemptConfirmReset]: produce((state, payload) => {
            state.loading = true;
            state.error = initialState.error;
            state.formErrors = initialState.formErrors;
            state.successMessage = initialState.successMessage;
        }),
        [ConfirmResetSuccess]: produce((state, payload) => {
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.formErrors = initialState.payload;
            state.successMessage = `Success! You can now login with your new password.`;
        }),
    },
    initialState
);
