import { hot } from 'react-hot-loader/root'; // Must be imported before React
import React, { useEffect } from 'react';
import { ThemeProvider } from 'theme-ui';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import FourOhFourPage from './pages/404';
import AuthPage from './pages/AuthPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import SearchPage from './pages/SearchPage';
import DetailPage from './pages/DetailPage';
import AddFlightPage from './pages/AddFlightPage';
import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import { FetchProfile } from './redux/auth.actions';
import { FetchStates } from './redux/st.actions';
import theme from './theme';
import { DEFAULT_ST } from './constants';
import SmartRedirect from './components/SmartRedirect';

function App({ fetchProfile, fetchStates, ST }) {
    // Attempt to fetch the user's profile here on app load.
    // If the user's session is active, they don't need to login.
    useEffect(() => {
        fetchProfile();
        fetchStates();
    }, [fetchProfile, fetchStates]);

    const desiredST = ST || DEFAULT_ST;

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <Switch>
                        {/* Redirects */}
                        <SmartRedirect from='/map' to={`/${desiredST}/map`} />
                        <SmartRedirect from='/grid' to={`/${desiredST}/grid`} />
                        <SmartRedirect from='/list' to={`/${desiredST}/list`} />
                        <Redirect
                            from='/tank/:tankId/flight/add-flight'
                            to={`/${desiredST}/tank/:tankId/flight/add-flight`}
                        />
                        <Redirect
                            from='/tank/:tankId/flight/:flightDate'
                            to={`/${desiredST}/tank/:tankId/flight/:flightDate`}
                        />
                        <Redirect
                            from='/tank/:tankId'
                            to={`/${desiredST}/tank/:tankId`}
                        />

                        <Route path='/user'>
                            <AuthPage />
                        </Route>
                        <Route
                            path='/email/confirm'
                            component={ConfirmEmailPage}
                        />
                        {/*
                            We use `ST` for the state variable, so as not to
                            collide with `state` or `useState` in React contexts.
                            ST also looks like a US State Abbreviation, and
                            mirrors the kind of values it'll have (NJ, PA, etc)
                        */}
                        <PrivateRoute
                            path='/:ST/tank/:tankId/add-flight'
                            component={AddFlightPage}
                        />
                        <PrivateRoute
                            path='/:ST/tank/:tankId/flight/:flightDate'
                            component={DetailPage}
                        />
                        <PrivateRoute
                            path='/:ST/tank/:tankId'
                            component={DetailPage}
                        />
                        <PrivateRoute path='/:ST' component={SearchPage} />

                        <Redirect from='/' to={`/${desiredST}`} />

                        <Route path='*' component={FourOhFourPage} />
                    </Switch>
                </ErrorBoundary>
            </ThemeProvider>
        </Router>
    );
}

const mapStateToProps = ({ st }) => ({
    ST: st.ST,
});

const mapDispatchToProps = dispatch => {
    return {
        fetchProfile: () => dispatch(FetchProfile()),
        fetchStates: () => dispatch(FetchStates()),
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
