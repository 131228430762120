/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Box, Text, Link, Button, IconButton } from 'theme-ui';
import { useEffect, useState } from 'react';
import { Popup } from 'react-map-gl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
    faExternalLinkAlt as faExternalLink,
    faCaretSquareLeft as faPageLeft,
    faCaretSquareRight as faPageRight,
} from '@fortawesome/free-solid-svg-icons';

import { CARD_SHADOW } from '../constants';
import { toMapcallUrl, getPressureZone, flightToDateString } from '../utils';
import MapcallWarning from './MapcallWarning';

const sx = {
    popup: {
        filter: CARD_SHADOW,
        '.mapboxgl-popup-content': {
            p: 0,
        },
    },
    card: {
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        bg: 'background',
        p: 2,
        minWidth: '32rem',
        maxWidth: '48rem',
        borderLeft: '8px solid',
        borderLeftColor: 'unknown',
    },
    facts: {},
    name: {
        mb: 1,
        fontSize: 4,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    fact: {
        mb: 1,
        fontSize: 0,
    },
    factValue: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    mapcall: {
        mt: 1,
        fontSize: 0,
        color: 'primary',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
    linkIcon: {
        ml: '0.4rem',
    },
    since: {
        color: 'textGray',
        fontSize: 0,
    },
    pagination: {
        position: 'absolute',
        right: 2,
        bottom: 2,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        mt: 2,
    },
    paginationText: {
        mx: 1,
        fontSize: 0,
    },
    paginationButton: {
        fontSize: 4,
        width: '1.6rem',
        height: '1.6rem',
        color: 'primary',
        ':disabled': {
            color: 'disabled',
            cursor: 'default',
        },
    },
};

function MapCard({ tanks, onSelect, ...props }) {
    const [tankIdx, setTankIdx] = useState(0);

    // Reset tank index on MapCard switch to another cluster
    useEffect(() => {
        setTankIdx(0);
    }, [tanks]);

    // Protect against going from a high tank to a cluster with fewer tanks
    const idx = tankIdx < tanks.length ? tankIdx : 0;
    const tank = tanks[idx];

    const lastFlight =
        tank.flights.length > 0 && tank.flights[tank.flights.length - 1];

    return (
        <Popup
            sx={sx.popup}
            closeButton={false}
            closeOnClick={false}
            {...props}
        >
            <Box sx={{ ...sx.card, borderLeftColor: tank.condition }}>
                <Box sx={sx.facts}>
                    <Button
                        variant='text'
                        sx={sx.name}
                        onClick={() => onSelect(tank.id)}
                    >
                        {tank.facilityname}
                    </Button>
                    <Text sx={sx.fact}>
                        Operating Center:{' '}
                        <span sx={sx.factValue}>{tank.operatingcenter}</span>
                    </Text>
                    <Text sx={sx.fact}>
                        Pressure Zone:{' '}
                        <span sx={sx.factValue}>{getPressureZone(tank)}</span>
                    </Text>
                    <Text sx={sx.fact}>
                        PWSID: <span sx={sx.factValue}>{tank.pwsid}</span>
                    </Text>
                    <Text sx={sx.fact}>
                        Condition:{' '}
                        <span sx={sx.factValue}>{tank.condition}</span>{' '}
                        {lastFlight && (
                            <span sx={sx.since}>
                                as of {flightToDateString(lastFlight)}
                            </span>
                        )}
                    </Text>
                    <MapcallWarning>
                        <Link
                            sx={sx.mapcall}
                            href={toMapcallUrl(tank)}
                            target='_blank'
                        >
                            Mapcall
                            <Icon icon={faExternalLink} sx={sx.linkIcon} />
                        </Link>
                    </MapcallWarning>
                </Box>
                {tanks.length > 1 && (
                    <Flex sx={sx.pagination}>
                        <IconButton
                            sx={sx.paginationButton}
                            disabled={tankIdx < 1}
                            onClick={() => setTankIdx(tankIdx - 1)}
                        >
                            <Icon icon={faPageLeft} />
                        </IconButton>{' '}
                        <span sx={sx.paginationText}>
                            {tankIdx + 1} of {tanks.length}
                        </span>
                        <IconButton
                            sx={sx.paginationButton}
                            disabled={tankIdx >= tanks.length - 1}
                            onClick={() => setTankIdx(tankIdx + 1)}
                        >
                            <Icon icon={faPageRight} />
                        </IconButton>
                    </Flex>
                )}
            </Box>
        </Popup>
    );
}

export default MapCard;
