/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { ORBITS } from '../constants';

const sx = {
    group: {
        background: '#fff',
    },
    button: {
        display: 'block',
        p: 0,
        fontSize: '1.6rem',
        pointerEvents: 'auto',
        transform: 'translate(0)',
        outline: 'none',
        border: 0,
        bg: 'transparent',
        cursor: ' pointer',
        ':not(:first-of-type)': {
            borderTop: '1px solid #ddd',
        },
    },
    icon: {},
};

function OrbitButton({ onSelect, orbit, title, selected, disabled, ...props }) {
    const selectedSx = selected ? { color: '#fff', bg: 'primary' } : {};
    const disabledSx = disabled
        ? { color: 'disabled', bg: 'background', cursor: 'default' }
        : {};

    let rotation = 180;
    if (orbit === ORBITS.HIGH) rotation -= 45;
    if (orbit === ORBITS.LOW) rotation += 45;
    const rotationSx = { transform: `rotate(${rotation}deg)` };

    return (
        <button
            key={orbit}
            type='button'
            title={title}
            onClick={onSelect}
            sx={{ ...sx.button, ...selectedSx, ...disabledSx }}
        >
            <Icon icon={faVideo} sx={{ ...sx.icon, ...rotationSx }} />
        </button>
    );
}

function OrbitControl({ onChange, orbit, disabled, ...props }) {
    return (
        <div sx={sx.group} {...props}>
            <OrbitButton
                orbit={ORBITS.HIGH}
                title='Above tank'
                onSelect={() => onChange(ORBITS.HIGH)}
                selected={orbit === ORBITS.HIGH}
                disabled={disabled}
            />
            <OrbitButton
                orbit={ORBITS.MID}
                title='Mid-tank'
                onSelect={() => onChange(ORBITS.MID)}
                selected={orbit === ORBITS.MID}
                disabled={disabled}
            />
            <OrbitButton
                orbit={ORBITS.LOW}
                title='Below tank'
                onSelect={() => onChange(ORBITS.LOW)}
                selected={orbit === ORBITS.LOW}
                disabled={disabled}
            />
        </div>
    );
}

export default OrbitControl;
