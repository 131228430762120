/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
    useHistory,
    Switch,
    Route,
    Redirect,
    useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';

import { applyTankFilters, logError } from '../utils';
import { URLS } from '../constants';
import { getTanks, swrConfig } from '../api';
import FilterBar from '../components/FilterBar';
import SearchMap from '../components/SearchMap';
import SearchGrid from '../components/SearchGrid';
import SearchList from '../components/SearchList';
import PageLayout from '../components/PageLayout';
import FourOhFourPage from './404';
import { FetchProfile } from '../redux/auth.actions';

const sx = {
    appBar: {
        flex: 'none',
        zIndex: 2,
    },
    filterBar: {
        flex: 'none',
        zIndex: 1,
    },
    map: {
        flex: 'auto',
    },
};

function SearchPage() {
    const {
        location: { pathname },
        push,
    } = useHistory();
    const dispatch = useDispatch();
    const { ST } = useParams();
    const { data, error } = useSWR(URLS.tanks(ST), getTanks, swrConfig);

    const navToTank = tankId =>
        push(`/${ST}/tank/${tankId}`, { from: pathname });

    const filters = useSelector(state => state.filters);

    if (error) {
        if (error.status === 403) {
            dispatch(FetchProfile());
        } else {
            logError(error);
        }

        if (!data) {
            return <FourOhFourPage subheading={error.subheading} />;
        }
    }

    const filteredData = applyTankFilters(data, filters);

    return (
        <PageLayout
            isLoading={!data}
            mode='search'
            sxAppBar={sx.appBar}
            ST={ST}
        >
            <FilterBar tanks={data} sx={sx.filterBar} />
            <Switch>
                <Route path={`/${ST}/list`}>
                    <SearchList tanks={filteredData} onTankSelect={navToTank} />
                </Route>
                <Route path={`/${ST}/grid`}>
                    <SearchGrid
                        tanks={filteredData}
                        onTankSelect={navToTank}
                        ST={ST}
                    />
                </Route>
                <Route path={`/${ST}/map`}>
                    <SearchMap
                        tanks={filteredData}
                        onTankSelect={navToTank}
                        sx={sx.map}
                    />
                </Route>
                <Route path={`/${ST}/`}>
                    <Redirect to={`/${ST}/map`} />
                </Route>
            </Switch>
        </PageLayout>
    );
}

export default SearchPage;
