/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Alert, Close } from 'theme-ui';
import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactMapGL, {
    NavigationControl,
    FlyToInterpolator,
    WebMercatorViewport,
} from 'react-map-gl';
import { LngLatBounds } from 'mapbox-gl';
import useSupercluster from 'use-supercluster';

import TankMarker from './TankMarker';
import ClusterMarker from './ClusterMarker';
import MapCard from './MapCard';
import LocationSearch from './LocationSearch';

import { CONDITIONS } from '../constants';
import MAP_STYLE from '../mapStyle.json';
import { SetViewport, AllowAutoZoom } from '../redux/map.actions';

const sx = {
    container: {
        position: 'relative',
    },
    mapControl: {
        position: 'absolute',
        right: '1.6rem',
        borderRadius: '2px',
        ':not(:empty)': {
            boxShadow: 1,
        },
        '> button': {
            width: '4rem',
            height: '4rem',
        },
    },
    zoom: {
        top: '7rem',
    },
};

const AUTO_ZOOM_PADDING = 0.1; // 10% of smaller of width or height
const AUTO_ZOOM_SPEED = 3.0; // Default value is 1.2
const ENABLE_AUTO_ZOOM_AFTER_MS = 1000;

function SearchMap({ tanks, onTankSelect, ...props }) {
    const dispatch = useDispatch();

    const { STDetail } = useSelector(state => state.st);
    const { viewport, allowAutoZoom } = useSelector(state => state.map);
    const setViewport = useCallback(payload => dispatch(SetViewport(payload)), [
        dispatch,
    ]);
    const [activeTanks, setActiveTanks] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const mapRef = useRef();

    // When the tanks or active tanks change, if the currently selected tank(s)
    // are hidden by the filters, then close the map card.
    useEffect(() => {
        if (activeTanks) {
            const someActiveTanksAreHidden = activeTanks.tanks.some(
                at => !tanks.find(t => at.id === t.id)
            );
            if (someActiveTanksAreHidden) setActiveTanks(null);
        }
    }, [tanks, activeTanks]);

    // Convert tank array to GeoJSON for calculating clusters
    const points = useMemo(
        () =>
            tanks?.map(tank => ({
                type: 'Feature',
                geometry: tank.geom,
                properties: {
                    cluster: false,
                    ...tank,
                },
            })) || [],
        [tanks]
    );

    // On initial render, we don't want to auto-zoom to filtered tanks, in case
    // the previous viewport was meaningful, e.g. user adjusting the map, going
    // to a tank's detail, then coming back. We wait, approximating the time it
    // takes for the map to render and stabilize, before allowing auto-zooming.
    useEffect(
        () =>
            setTimeout(
                () => dispatch(AllowAutoZoom()),
                ENABLE_AUTO_ZOOM_AFTER_MS
            ),
        [dispatch]
    );

    // Auto-zoom map to filtered tanks on change
    useEffect(() => {
        if (allowAutoZoom && mapRef.current && points.length > 0) {
            const tankBounds = new LngLatBounds();
            points.forEach(p => tankBounds.extend(p.geometry.coordinates));

            const wmv = new WebMercatorViewport(viewport);
            const padding = Math.min(wmv.width, wmv.height) * AUTO_ZOOM_PADDING;

            const {
                longitude,
                latitude,
                zoom,
            } = wmv.fitBounds(tankBounds.toArray(), { padding });

            setViewport({
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionDuration: 'auto',
                transitionInterpolator: new FlyToInterpolator({
                    speed: AUTO_ZOOM_SPEED,
                }),
            });
        }
        // We only want to update the viewport if the filtered points change.
        // The origin viewport is overridden, so its value doesn't really matter.
        // Could not find a way to convince React of that, thus instructing to ignore.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points]);

    // Initial load fits map to the ST boundary
    // Revisits to the search map reinitializes it to the ST extent, clobbering
    // the user's last custom viewport. This is not ideal UX.
    // TODO: https://github.com/azavea/american-water-tank-magic/issues/340
    useEffect(() => {
        if (
            allowAutoZoom &&
            mapRef.current &&
            STDetail.data &&
            !STDetail.fetching
        ) {
            // Coordinates can be nested across sibling arrays
            const coordinates = STDetail.data.geometry.coordinates.flat(2);
            const STBounds = new LngLatBounds();
            coordinates.forEach(c => STBounds.extend(c));

            const wmv = new WebMercatorViewport(viewport);
            const padding = Math.min(wmv.width, wmv.height) * AUTO_ZOOM_PADDING;

            const {
                longitude,
                latitude,
                zoom,
            } = wmv.fitBounds(STBounds.toArray(), { padding });

            setViewport({
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionDuration: 'auto',
                transitionInterpolator: new FlyToInterpolator({
                    speed: AUTO_ZOOM_SPEED,
                }),
            });
        }
        // We only want to update the viewport to the ST boundaries.
        // The default viewport is overridden, so its value doesn't really matter.
        // Could not find a way to convince React of that, thus instructing to ignore.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [STDetail, allowAutoZoom]);

    // Get map bounds for calculating clusters
    const bounds =
        mapRef.current && mapRef.current.getMap().getBounds().toArray().flat();

    // Setup clusters
    const { clusters } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: {
            // See https://github.com/leighhalliday/use-supercluster#map--reduce-options
            // Initialize accumulator
            map: tank => {
                const acc = {
                    tankIds: `${tank.id},`, // trailing comma for separation
                    [tank.condition]: 1,
                };

                return acc;
            },
            // Accumulate for every tank
            reduce: (acc, props) => {
                // Conditions are summed
                for (const condition of CONDITIONS) {
                    acc[condition] =
                        (acc[condition] || 0) + (props[condition] || 0);
                }
                // Tank IDs are concatenated
                acc.tankIds = (acc.tankIds || '') + props.tankIds;
            },
        },
    });

    return (
        <Box sx={sx.container} {...props}>
            {!!errorMessage && (
                <Alert>
                    {errorMessage}
                    <Close
                        ml='auto'
                        mr={-2}
                        onClick={() => setErrorMessage('')}
                    />
                </Alert>
            )}
            <ReactMapGL
                {...viewport}
                width='100%'
                height='100%'
                mapStyle={MAP_STYLE}
                onViewportChange={setViewport}
                ref={mapRef}
                onClick={() => activeTanks && setActiveTanks(null)}
                style={{ position: 'absolute' }}
                dragRotate={false}
            >
                {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { cluster: isCluster } = cluster.properties;

                    if (isCluster) {
                        const {
                            point_count: tankCount,
                            tankIds,
                        } = cluster.properties;

                        // Convert tank IDs from comma separated String
                        // (with an extra comma at the end) to an array of UUIDs
                        const tankIdsInCluster = tankIds
                            .slice(0, -1)
                            .split(',');

                        return (
                            <ClusterMarker
                                key={`cluster-${cluster.id}`}
                                tankCount={tankCount}
                                conditionCounts={cluster.properties}
                                longitude={longitude}
                                latitude={latitude}
                                onClick={() =>
                                    setActiveTanks({
                                        tanks: tanks.filter(({ id }) =>
                                            tankIdsInCluster.includes(id)
                                        ),
                                        longitude,
                                        latitude,
                                    })
                                }
                            />
                        );
                    }

                    const tank = cluster.properties;

                    return (
                        <TankMarker
                            key={`tank-${tank.id}`}
                            condition={tank.condition}
                            longitude={longitude}
                            latitude={latitude}
                            onClick={() =>
                                setActiveTanks({
                                    tanks: [tank],
                                    longitude,
                                    latitude,
                                })
                            }
                        />
                    );
                })}
                {activeTanks && (
                    <MapCard
                        tanks={activeTanks.tanks}
                        longitude={activeTanks.longitude}
                        latitude={activeTanks.latitude}
                        onClose={() => setActiveTanks(null)}
                        onSelect={tankId => onTankSelect(tankId)}
                    />
                )}
                <LocationSearch setErrorMessage={setErrorMessage} />
                <NavigationControl
                    sx={{ ...sx.mapControl, ...sx.zoom }}
                    showCompass={false}
                />
            </ReactMapGL>
        </Box>
    );
}

export default SearchMap;
