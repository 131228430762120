import { createAction } from 'redux-act';

import { ResetFilters } from './filters.actions';
import { API } from '../api';
import { URLS } from '../constants';

export const SetSTList = createAction('Set list of states in the USA');
export const SetST = createAction('Set selected ST (USA state)');
export const FetchSTDetail = createAction('Fetch ST (USA state) detail');
export const SetSTDetail = createAction('Set selected ST (USA state) detail');
export const SetSTError = createAction(
    'Set error fetching ST (USA state) detail'
);

export const FetchStates = () => async dispatch => {
    const response = await API.get(URLS.states());
    return dispatch(SetSTList(response.data));
};

export const ChangeAppST = ST => async (dispatch, getState) => {
    const {
        st: { STDetail },
    } = getState();

    try {
        // Reset filters if switching STs while authenticated
        if (STDetail.data?.properties) {
            dispatch(ResetFilters());
        }

        dispatch(FetchSTDetail());
        const response = await API.get(URLS.state(ST));

        // for good measure but low utility action call in this scenario
        dispatch(SetST(ST));

        return dispatch(SetSTDetail(response.data));
    } catch (e) {
        return dispatch(SetSTError(e));
    }
};
