/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Grid } from 'theme-ui';
import { useState } from 'react';
import SortBar from '../components/SortBar';
import SearchChart from '../components/SearchChart';

import { sortTanks } from '../utils';
import { SORTS } from '../constants';

const sx = {
    page: {
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    appBar: {
        flex: 'none',
        zIndex: 2,
    },
    filterBar: {
        flex: 'none',
        zIndex: 1,
    },
    sortBar: {
        flex: 'none',
        mb: 2,
    },
    tanks: {
        flex: 'auto',
        px: 3,
        pb: 4,
        gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
        gridGap: 3,
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
    },
    chart: {
        width: '16rem',
        '&:hover': {
            zIndex: 1,
        },
    },
};

function SearchGrid({ tanks, onTankSelect, ST }) {
    const [sort, setSort] = useState(SORTS.CONDITION);
    const [desc, setDesc] = useState(false);

    const sortedTanks = sortTanks(tanks, sort, desc);

    return (
        <Flex sx={sx.page}>
            <SortBar
                sx={sx.sortBar}
                tanks={tanks}
                onSort={setSort}
                sort={sort}
                desc={desc}
                setDesc={setDesc}
            />
            <Grid sx={sx.tanks}>
                {sortedTanks.map(tank => (
                    <SearchChart
                        key={tank.id}
                        sx={sx.chart}
                        onSelect={onTankSelect}
                        tank={tank}
                        ST={ST}
                    />
                ))}
            </Grid>
        </Flex>
    );
}

export default SearchGrid;
