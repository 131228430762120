/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex, IconButton } from 'theme-ui';
import { useState, useRef } from 'react';
import usePortal from 'react-useportal';
import Calendar from 'react-calendar';
import { isBefore, differenceInDays } from 'date-fns';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Prev2 } from '../img/Prev2.svg';
import { ReactComponent as Prev } from '../img/Prev.svg';
import { ReactComponent as Next2 } from '../img/Next2.svg';
import { ReactComponent as Next } from '../img/Next.svg';

import 'react-calendar/dist/Calendar.css';

import { YMDToDate } from '../utils';

const sx = {
    container: {
        position: 'relative',
    },
    button: {
        variant: 'forms.input',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        textAlign: 'left',
        width: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        py: 1,
        pr: 4,
        pl: 2,
        whiteSpace: 'nowrap',
        cursor: 'default',
    },
    arrow: {
        margin: 0,
        minWidth: 0,
        marginLeft: '-28px',
        alignSelf: 'center',
        pointerEvents: 'none',
    },
    clear: {
        boxSizing: 'border-box',
        margin: 0,
        minWidth: 0,
        marginLeft: '-3.2rem',
        alignSelf: 'center',
        cursor: 'pointer',
    },
    scrim: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '15%',
        bg: 'scrim',
    },
    panel: {
        bg: 'background',
        boxShadow: 2,
    },
    calendar: {
        '& button': {
            font: 'inherit',
            fontSize: 3,
        },
        '& .react-calendar__tile--active': {
            bg: 'primary',
        },
        '& .react-calendar__tile--active:enabled:hover': {
            bg: 'primary',
        },
        '& .react-calendar__tile--active:enabled:focus': {
            bg: 'primary',
        },
    },
    calendarNav: {
        height: '1.4em',
        verticalAlign: 'middle',
    },
};

const arrow = (
    <svg
        sx={sx.arrow}
        aria-hidden='true'
        focusable='false'
        role='img'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='currentcolor'
    >
        <path d='M7 10l5 5 5-5z'></path>
    </svg>
);

function isValueLegit(v) {
    if (v instanceof Date) {
        return true;
    } else if (Array.isArray(v) && v.length === 2) {
        if (
            !(v[0] instanceof Date) ||
            !(v[1] instanceof Date) ||
            isBefore(v[1], v[0])
        ) {
            return false;
        } else {
            return true;
        }
    }

    return false;
}

function FlightDatePicker({
    value,
    placeholder = '\u00a0', // non-breaking space, to ensure correct height
    onChange = () => {},
    allowRange = false,
    showArrow = true,
    ...props
}) {
    const [showCalendar, setShowCalendar] = useState(false);
    const { Portal } = usePortal();
    const scrimRef = useRef();

    let dates = value?.split('--').map(YMDToDate);
    if (dates?.length === 1) {
        dates = dates[0];
    }

    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowCalendar(show => !show);
    }

    function handleChange(v) {
        setShowCalendar(false);

        if (Array.isArray(v) && differenceInDays(v[0], v[1]) === 0) {
            onChange(v[0]);
        } else {
            onChange(v);
        }
    }

    function renderText() {
        if (!isValueLegit(dates)) {
            return placeholder;
        }

        if (dates instanceof Date) {
            return dates.toLocaleDateString();
        }

        if (Array.isArray(dates)) {
            if (differenceInDays(dates[1], dates[0]) === 0) {
                return dates[0].toLocaleDateString();
            } else {
                return `${dates[0].toLocaleDateString()} – ${dates[1].toLocaleDateString()}`;
            }
        }

        // Should never reach this
        return placeholder;
    }

    return (
        <Flex sx={sx.container} {...props}>
            <button sx={sx.button} onClick={handleClick}>
                {renderText()}
            </button>
            {isValueLegit(value) ? (
                <IconButton sx={sx.clear} onClick={() => onChange(null)}>
                    <Icon icon={faTimes} />
                </IconButton>
            ) : (
                showArrow && arrow
            )}
            {showCalendar && (
                <Portal>
                    <Box
                        sx={sx.scrim}
                        ref={scrimRef}
                        onClick={e => {
                            e.target === scrimRef.current &&
                                setShowCalendar(false);
                        }}
                    >
                        <Box sx={sx.panel}>
                            <Calendar
                                sx={sx.calendar}
                                value={
                                    allowRange && dates instanceof Date
                                        ? [dates, dates]
                                        : dates
                                }
                                onChange={handleChange}
                                maxDate={new Date()}
                                minDetail='year'
                                selectRange={allowRange}
                                calendarType='US'
                                prevLabel={<Prev sx={sx.calendarNav} />}
                                prev2Label={<Prev2 sx={sx.calendarNav} />}
                                nextLabel={<Next sx={sx.calendarNav} />}
                                next2Label={<Next2 sx={sx.calendarNav} />}
                            />
                        </Box>
                    </Box>
                </Portal>
            )}
        </Flex>
    );
}

export default FlightDatePicker;
