/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button } from 'theme-ui';
import { ReactComponent as AddFlight } from '../img/AddFlight.svg';

const sx = {
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        py: 1,
        fontSize: 4,
        fontWeight: 'normal',
    },
    icon: {
        color: '#fff',
        height: '2em',
        mr: 1,
    },
};

function AddFirstFlightButton({ onClick, ...props }) {
    return (
        <Button variant='cta' sx={sx.button} onClick={onClick} {...props}>
            <AddFlight sx={sx.icon} title='Add first flight' />
            Add first flight
        </Button>
    );
}

export default AddFirstFlightButton;
