/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Select } from 'theme-ui';

const sx = {
    select: {
        py: 1,
        pr: 4,
        width: 'auto',
        height: 'auto',
    },
};

const STSelector = ({ selectedST, onSelectST, STList, ...props }) => (
    <Select
        sx={sx.select}
        value={selectedST}
        onChange={e => onSelectST(e.target.value)}
        {...props}
    >
        {STList?.map(({ name, id, enabled }) => (
            <option
                sx={{ color: enabled ? 'text' : 'disabled' }}
                disabled={!enabled}
                key={id}
                value={id}
            >
                {name}
            </option>
        ))}
    </Select>
);

export default STSelector;
