/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Spinner } from 'theme-ui';
import { useEffect } from 'react';
import { Route, useHistory, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChangeAppST, SetST } from '../redux/st.actions';
import { canUserViewST, isSTValidAndEnabled } from '../utils';
import { DEFAULT_ST, URLS } from '../constants';

// inspired by https://ui.dev/react-router-v4-protected-routes-authentication/

const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    setST,
    changeAppST,
    STDetail,
    ST,
    STList,
    ...rest
}) => {
    const {
        location: { pathname },
        replace,
        push,
    } = useHistory();

    // if the user is logged out (false), redirect to login.
    useEffect(() => {
        if (isAuthenticated === false) {
            // capture ST user attempted to navigate to
            const { params } = matchPath(pathname, { path: '/:ST/' });
            if (params?.ST) {
                const newST = params.ST.toUpperCase();
                const stIsValid = isSTValidAndEnabled(newST, STList);

                if (!STList.length || stIsValid) {
                    setST(newST);
                }
            }
            replace('/user/login', { from: pathname });
        }
    }, [isAuthenticated, pathname, setST, STList, replace]);

    // if the user is logged in, fetch ST detail
    useEffect(() => {
        if (!!isAuthenticated && STList.length) {
            const { params } = matchPath(pathname, { path: '/:ST/' });
            const routeST = params?.ST?.toUpperCase();
            const desiredST = canUserViewST(isAuthenticated, routeST, STList)
                ? routeST
                : canUserViewST(isAuthenticated, ST, STList)
                ? ST
                : DEFAULT_ST;

            if (routeST !== desiredST) {
                return push(URLS.homepage(desiredST));
            }

            if (STDetail.error) {
                return push(URLS.homepage(DEFAULT_ST));
            }

            const STData = STDetail?.data;
            const isSTDetailEmpty = STData === null;
            const isNewSTSelected = STData?.id !== desiredST;
            if (
                (isSTDetailEmpty || isNewSTSelected) &&
                !STDetail.fetching &&
                !STDetail.error
            ) {
                changeAppST(desiredST);
            }
        }
    }, [
        isAuthenticated,
        pathname,
        setST,
        changeAppST,
        ST,
        STDetail,
        STList,
        push,
    ]);

    if (isAuthenticated === false) return null;

    // if the user's authentication is unknown (null), return a loading spinner.
    if (isAuthenticated === null) return <Spinner />;

    // otherwise, the user is authenticated; load the protected route.
    return (
        <Route
            {...rest}
            render={props => {
                return <Component {...props} />;
            }}
        />
    );
};

const mapStateToProps = ({ auth, st }) => ({
    isAuthenticated: auth.user,
    STDetail: st.STDetail,
    ST: st.ST,
    STList: st.STList,
});

const mapDispatchToProps = dispatch => ({
    setST: ST => dispatch(SetST(ST)),
    changeAppST: ST => dispatch(ChangeAppST(ST)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
