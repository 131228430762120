/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Select } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import Condition from './Condition';
import { flightToDateString } from '../../utils';
import { JOB_STATUS } from '../../constants';

const sx = {
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        py: '0.4rem',
        fontSize: 2,
    },
    date: {
        flex: 'none',
        appearance: 'none',
        width: 'auto',
        textAlign: 'left',
        fontWeight: 'bold',
        p: 0,
        pr: '2.8rem',
        bg: 'transparent',
        border: 0,
        cursor: 'pointer',
    },
    condition: {
        flex: 'none',
    },
};

function FlightHeader({ ST, tank, flight, ...props }) {
    const history = useHistory();

    const onFlightSelect = e =>
        history.push(`/${ST}/tank/${tank.id}/flight/${e.target.value}`);

    return (
        <Flex sx={sx.header} {...props}>
            <Select
                sx={sx.date}
                value={flight.flight_date}
                onChange={onFlightSelect}
            >
                {tank.flights.map(f => (
                    <option key={f.flight_date} value={f.flight_date}>
                        {flightToDateString(f)}
                    </option>
                ))}
            </Select>
            <Condition
                sx={sx.condition}
                condition={flight.condition_override || flight.condition}
                conditionedAt={
                    (flight.condition_override && flight.overridden_at) ||
                    flight.conditioned_at
                }
                isProcessing={flight.processing === JOB_STATUS.STARTED}
            />
        </Flex>
    );
}

export default FlightHeader;
