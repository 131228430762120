import { createReducer } from 'redux-act';
import produce from 'immer';
import { set } from '../utils';

import {
    SetViewport,
    SetMapSearchPoint,
    AllowAutoZoom,
    ForbidAutoZoom,
} from './map.actions';

export const initialState = {
    viewport: {
        latitude: 40.07,
        longitude: -74.558333,
        zoom: 8,
    },
    allowAutoZoom: false,
};

export default createReducer(
    {
        [SetViewport]: set('viewport'),
        [SetMapSearchPoint]: produce((state, payload) => {
            state.viewport = {
                ...state.viewport,
                ...payload,
                zoom: 17,
            };
        }),
        [AllowAutoZoom]: produce(state => {
            state.allowAutoZoom = true;
        }),
        [ForbidAutoZoom]: produce(state => {
            state.allowAutoZoom = false;
        }),
    },
    initialState
);
