import { combineReducers } from 'redux';

import MapReducer from './map.reducer';
import FiltersReducer from './filters.reducer';
import AuthReducer from './auth.reducer';
import STReducer from './st.reducer';

export default combineReducers({
    map: MapReducer,
    filters: FiltersReducer,
    auth: AuthReducer,
    st: STReducer,
});
