/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Heading, Select, IconButton } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
    faSortAmountDown as faDesc,
    faSortAmountDownAlt as faAsc,
} from '@fortawesome/free-solid-svg-icons';
import { SORTS } from '../constants';

const sx = {
    sortBar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 3,
        py: 2,
    },
    count: {
        fontSize: 6,
        fontWeight: 'normal',
        flex: 'none',
    },
    controls: {
        flex: 'none',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    select: {
        flex: 'none',
        py: 1,
        width: '20rem',
    },
    direction: {
        flex: 'none',
        ml: 1,
    },
};

function SortBar({ tanks, sort, onSort, desc, setDesc, ...props }) {
    return (
        <Flex sx={sx.sortBar} {...props}>
            <Heading as='h2' sx={sx.count}>
                {tanks?.length || 0} tanks
            </Heading>
            <Flex sx={sx.controls}>
                <Select sx={sx.select} onChange={e => onSort(e.target.value)}>
                    <option value={SORTS.CONDITION}>Sort by condition</option>
                    <option value={SORTS.FLIGHT}>
                        Sort by last flight date
                    </option>
                    <option value={SORTS.NAME}>Sort by name</option>
                    <option value={SORTS.OPCENTER}>
                        Sort by operating center
                    </option>
                    <option value={SORTS.PWSID}>Sort by pwsid</option>
                </Select>
                <IconButton sx={sx.direction} onClick={() => setDesc(!desc)}>
                    <Icon icon={desc ? faDesc : faAsc} />
                </IconButton>
            </Flex>
        </Flex>
    );
}

export default SortBar;
