/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const sx = {
    group: {
        background: '#fff',
    },
    button: {
        display: 'block',
        p: 0,
        fontSize: '1.6rem',
        textAlign: 'center',
        lineHeight: '4rem',
        color: 'inherit',
        pointerEvents: 'auto',
        transform: 'translate(0)',
        outline: 'none',
        border: 0,
        bg: 'transparent',
        cursor: 'pointer',
        ':not(:first-of-type)': {
            borderTop: '1px solid #ddd',
        },
    },
};

function MapControl({
    onClick,
    title,
    children,
    selected,
    disabled,
    href,
    target,
    download,
    ...props
}) {
    const selectedSx = selected ? { color: '#fff', bg: 'primary' } : {};
    const disabledSx = disabled
        ? { color: 'disabled', bg: 'background', cursor: 'default' }
        : {};

    const isLink = !!href;

    const El = isLink ? 'a' : 'button';

    return (
        <div sx={sx.group} {...props}>
            <El
                type={isLink ? null : 'button'}
                title={title}
                onClick={onClick}
                href={href}
                target={target}
                download={download}
                sx={{
                    ...sx.button,
                    ...selectedSx,
                    ...disabledSx,
                }}
            >
                {children}
            </El>
        </div>
    );
}

export default MapControl;
