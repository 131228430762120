/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Redirect, useLocation } from 'react-router-dom';

/* Preserve query params across a redirect */

const SmartRedirect = ({ to, ...props }) => {
    let location = useLocation();

    return (
        <Redirect
            to={{
                pathname: to,
                search: location.search,
            }}
            {...props}
        />
    );
};

export default SmartRedirect;
