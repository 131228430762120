/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Marker } from 'react-map-gl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { BEARING_ANGLES, MARKER_SHADOW } from '../constants';

function CameraMarker({ bearing, selected, disabled, onClick, ...props }) {
    const markerSx = {
        fontSize: '16px', // TODO Should probably be zoom-dependent
        color: (disabled && 'disabled') || (selected && 'accent') || 'primary',
        cursor: disabled ? 'default' : 'pointer !important',
    };

    const iconSx = {
        transform: `rotate(${90 + BEARING_ANGLES[bearing]}deg)`,
        filter: disabled ? null : MARKER_SHADOW,
    };

    return (
        <Marker
            key={`camera-${bearing}`}
            sx={markerSx}
            offsetTop={-8}
            offsetLeft={-8}
            {...props}
        >
            <Icon icon={faVideo} sx={iconSx} onClick={onClick} />
        </Marker>
    );
}

export default CameraMarker;
