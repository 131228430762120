/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';

import AppBar from '../components/AppBar';

const sx = {
    page: {
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    appBar: {
        flex: 'none',
    },
};

function PageLayout({
    sxPage = sx.page,
    sxAppBar = sx.appBar,
    children,
    ...props
}) {
    return (
        <Flex sx={sxPage}>
            <AppBar sx={sxAppBar} {...props} />
            {children}
        </Flex>
    );
}

export default PageLayout;
