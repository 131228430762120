/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import { Marker } from 'react-map-gl';
import { VictoryPie } from 'victory';
import { CONDITION_COLORS, MARKER_SHADOW } from '../constants';

const sx = {
    container: {
        position: 'relative',
        width: '32px',
        height: '32px',
        bg: '#fff',
        borderRadius: '50%',
        filter: MARKER_SHADOW,
        cursor: 'pointer',
    },
    label: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        pointerEvents: 'none',
        fontSize: 0,
        fontWeight: 'bold',
    },
};

const categories = Object.keys(CONDITION_COLORS);
const colorScale = Object.values(CONDITION_COLORS);

function ClusterMarker({ conditionCounts, tankCount, onClick, ...props }) {
    // Convert buckets of categories into data format for chart
    const data = Object.keys(CONDITION_COLORS).map(c => {
        return { condition: c, count: conditionCounts[c] || 0 };
    });

    return (
        <Marker offsetLeft={-16} offsetTop={-16} {...props}>
            <Box sx={sx.container} onClick={onClick}>
                <VictoryPie
                    data={data}
                    x='condition'
                    y='count'
                    categories={categories}
                    colorScale={colorScale}
                    labels={[]}
                    radius={190}
                    innerRadius={130}
                />
                <Flex
                    sx={{
                        ...sx.label,
                        fontSize: tankCount > 99 ? '0.8rem' : 0,
                    }}
                >
                    {tankCount > 99 ? '99+' : tankCount}
                </Flex>
            </Box>
        </Marker>
    );
}

export default ClusterMarker;
