import produce from 'immer';
import { createReducer } from 'redux-act';
import { dateToYMD, set } from '../utils';

import {
    SetQuery,
    SetOpCenterFilter,
    SetFlightDatesFilter,
    SetPressureZoneFilter,
    SetPwsidFilter,
    SetConditionFilter,
    ResetFilters,
    UpdateAllFilters,
} from './filters.actions';

export const initialState = {
    query: '',
    opCenter: '',
    flightDates: null,
    pressureZone: '',
    pwsid: '',
    condition: '',
};

export default createReducer(
    {
        [SetQuery]: set('query'),
        [SetOpCenterFilter]: set('opCenter'),
        [SetFlightDatesFilter]: produce((state, payload) => {
            if (payload instanceof Date) {
                state.flightDates = dateToYMD(payload);
            }

            if (Array.isArray(payload)) {
                state.flightDates = `${dateToYMD(payload[0])}--${dateToYMD(
                    payload[1]
                )}`;
            }
        }),
        [SetPressureZoneFilter]: set('pressureZone'),
        [SetPwsidFilter]: set('pwsid'),
        [SetConditionFilter]: set('condition'),
        [ResetFilters]: produce((state, payload) => initialState),
        [UpdateAllFilters]: produce((state, payload) => payload),
    },
    initialState
);
