/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui';
import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import ReactMapGL, {
    Source,
    Layer,
    NavigationControl,
    WebMercatorViewport,
} from 'react-map-gl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
    faCloudDownloadAlt as faDownload,
    faExpandAlt as faExpand,
    faCompressAlt as faCollapse,
    faImages,
} from '@fortawesome/free-solid-svg-icons';

import MapControl from './MapControl';
import theme from '../theme';

const MIN_ZOOM = 5;

const sx = {
    mapControl: {
        position: 'absolute',
        right: '1.6rem',
        borderRadius: '2px',
        ':not(:empty)': {
            boxShadow: 1,
        },
        '> *': {
            width: '4rem',
            height: '4rem',
        },
        '> button': {
            width: '4rem',
            height: '4rem',
        },
    },
    fullscreen: {
        top: '1.6rem',
    },
    zoom: {
        top: '8rem',
    },
    rust: {
        top: '18.4rem',
    },
    download: {
        bottom: '1.6rem',
    },
    placeholder: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        opacity: 0.2,
    },
    placeholderIcon: {
        color: 'primary',
        mb: 2,
        fontSize: '16rem',
    },
    placeholderText: {
        fontSize: 4,
        fontWeight: 'bold',
        color: 'primary',
        textAlign: 'center',
    },
};

const mapStyle = {
    version: 8,
    sources: {},
    layers: [],
    transition: {
        duration: 0,
        delay: 0,
    },
};

const initialViewport = {
    latitude: 0,
    longitude: 0,
    zoom: MIN_ZOOM,
};

function clampToMaxBounds(viewport, maxBounds) {
    let { longitude, latitude, width, height } = viewport;
    const wmv = new WebMercatorViewport(viewport);
    const [viewportWest, viewportSouth] = wmv.unproject([0, viewport.height]);
    const [viewportEast, viewportNorth] = wmv.unproject([viewport.width, 0]);
    const [maxWest, maxSouth] = maxBounds.sw;
    const [maxEast, maxNorth] = maxBounds.ne;

    if (viewportWest < maxWest) {
        if (viewportNorth > maxNorth) {
            [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
                lngLat: [maxWest, maxNorth],
                pos: [0, 0],
            });
        } else if (viewportSouth < maxSouth) {
            [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
                lngLat: [maxWest, maxSouth],
                pos: [0, height],
            });
        } else {
            [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
                lngLat: [maxWest, viewportNorth],
                pos: [0, 0],
            });
        }
    } else if (viewportEast > maxEast) {
        if (viewportNorth > maxNorth) {
            [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
                lngLat: [maxEast, maxNorth],
                pos: [width, 0],
            });
        } else if (viewportSouth < maxSouth) {
            [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
                lngLat: [maxEast, maxSouth],
                pos: [width, height],
            });
        } else {
            [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
                lngLat: [maxEast, viewportNorth],
                pos: [width, 0],
            });
        }
    } else if (viewportNorth > maxNorth) {
        [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
            lngLat: [viewportWest, maxNorth],
            pos: [0, 0],
        });
    } else if (viewportSouth < maxSouth) {
        [longitude, latitude] = wmv.getMapCenterByLngLatPosition({
            lngLat: [viewportWest, maxSouth],
            pos: [0, height],
        });
    }

    return { ...viewport, longitude, latitude };
}

function Placeholder({ text, ...props }) {
    return (
        <Flex sx={sx.placeholder} {...props}>
            <Icon icon={faImages} sx={sx.placeholderIcon} />
            <Text sx={sx.placeholderText}>{text}</Text>
        </Flex>
    );
}

// adapted from https://github.com/UW-Macrostrat/gl-image-zoom/
function PhotoViewer({ photo, noFlights, isUploading, ...props }) {
    const [viewport, setViewport] = useState(initialViewport);
    const [viewportSize, setViewportSize] = useState(null);
    const [imageSize, setImageSize] = useState({ width: 5472, height: 3648 });
    const [maxBounds, setMaxBounds] = useState(null);
    const [imageCoords, setImageCoords] = useState(null);
    const containerRef = useRef();
    const mapRef = useRef();

    // Setup viewport and image geometry
    useLayoutEffect(() => {
        const img = new Image();
        img.crossOrigin = 'use-credentials';
        img.src = photo;
        img.onload = () => {
            setImageSize({ width: img.width, height: img.height });
        };
    }, [photo]);

    // Update max bounds, initially and whenever viewport size changes
    useEffect(() => {
        if (!imageSize || !viewportSize) return;

        const { width: viewportWidth, height: viewportHeight } = viewportSize;
        const { width: imageWidth, height: imageHeight } = imageSize;

        const ratio = Math.min(
            viewportWidth / imageWidth,
            viewportHeight / imageHeight
        );

        let wmv = new WebMercatorViewport({
            ...initialViewport,
            width: viewportWidth,
            height: viewportHeight,
        });

        // Set coordinates of image on map
        const imageSW = wmv.unproject([0, imageHeight * ratio]);
        const imageNE = wmv.unproject([imageWidth * ratio, 0]);
        setImageCoords([
            [imageSW[0], imageNE[1]],
            [imageNE[0], imageNE[1]],
            [imageNE[0], imageSW[1]],
            [imageSW[0], imageSW[1]],
        ]);

        const optimalViewport = wmv.fitBounds([imageSW, imageNE]);
        setViewport(optimalViewport);

        wmv = new WebMercatorViewport(optimalViewport);
        const viewportSW = wmv.unproject([0, viewportHeight]);
        const viewportNE = wmv.unproject([viewportWidth, 0]);

        setMaxBounds({ sw: viewportSW, ne: viewportNE });
    }, [imageSize, viewportSize]);

    const handleViewportChange = newViewport => {
        if (!maxBounds) return;

        // Avoid infinite setState loop
        // when clicking zoom out button while at min zoom.
        if (newViewport.zoom < MIN_ZOOM) return;

        setViewport(clampToMaxBounds(newViewport, maxBounds));
    };

    const [isFullscreen, setIsFullscreen] = useState(false);
    const fullscreenSX = isFullscreen
        ? {
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              width: '100%',
              height: '100%',
          }
        : {};

    return (
        <Box
            ref={containerRef}
            sx={{ position: isFullscreen ? 'static' : 'relative' }}
            {...props}
        >
            {photo && !noFlights && !isUploading && (
                <ReactMapGL
                    {...viewport}
                    ref={mapRef}
                    pitch={0}
                    bearing={0}
                    width='100%'
                    height='100%'
                    minZoom={MIN_ZOOM}
                    maxZoom={10}
                    mapStyle={mapStyle}
                    attributionControl={false}
                    onViewportChange={handleViewportChange}
                    onResize={size => setViewportSize(size)}
                    style={isFullscreen ? fullscreenSX : null}
                    {...props}
                >
                    <Layer
                        id='background'
                        type='background'
                        paint={{ 'background-color': theme.colors.secondary }}
                    />
                    {imageCoords && (
                        <Source
                            type='image'
                            url={photo}
                            coordinates={imageCoords}
                        >
                            <Layer
                                type='raster'
                                paint={{ 'raster-opacity': 1 }}
                            />
                        </Source>
                    )}
                    <MapControl
                        sx={{ ...sx.mapControl, ...sx.fullscreen }}
                        title='Toggle fullscreen'
                        onClick={() => setIsFullscreen(fs => !fs)}
                    >
                        <Icon icon={isFullscreen ? faCollapse : faExpand} />
                    </MapControl>
                    <NavigationControl
                        sx={{ ...sx.mapControl, ...sx.zoom }}
                        showCompass={false}
                    />
                    {isFullscreen || (
                        <MapControl
                            sx={{ ...sx.mapControl, ...sx.download }}
                            title='Download photo'
                            href={photo}
                            download
                        >
                            <Icon icon={faDownload} />
                        </MapControl>
                    )}
                </ReactMapGL>
            )}
            {noFlights && <Placeholder text='Add a flight to get started' />}
            {isUploading && <Placeholder text='Uploading …' />}
            {!photo && <Placeholder text='Missing photo' />}
        </Box>
    );
}

export default PhotoViewer;
