/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid, Button } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { ORBITS } from '../../constants';

const sx = {
    picker: {
        gridTemplateColumns: '1fr 1fr 1fr',
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: 'backgroundGray',
    },
    button: {
        height: '4rem',
        p: 0,
        fontSize: 1,
        borderWidth: '0 0 3px 0',
        borderStyle: 'solid',
        borderColor: 'transparent',
        bg: 'transparent',
        color: 'text',
        borderRadius: 0,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    },
    icon: {
        mr: 1,
    },
};

function OrbitButton({ orbit, onSelect, selected, disabled, label, ...props }) {
    const selectedSx = selected
        ? { color: 'primary', borderColor: 'primary', fontWeight: 'bold' }
        : {};
    const disabledSx = disabled
        ? {
              color: 'disabled',
              borderColor: 'transparent',
              fontWeight: 'normal',
              cursor: 'default',
          }
        : {};

    let rotation = 180;
    if (orbit === ORBITS.HIGH) rotation -= 45;
    if (orbit === ORBITS.LOW) rotation += 45;
    const rotationSx = { transform: `rotate(${rotation}deg)` };

    return (
        <Button
            sx={{ ...sx.button, ...selectedSx, ...disabledSx }}
            onClick={() => onSelect(orbit)}
            disabled={disabled}
            {...props}
        >
            <Icon icon={faVideo} sx={{ ...sx.icon, ...rotationSx }} />
            {label}
        </Button>
    );
}

function OrbitPicker({ orbit, onSelect, disabled, ...props }) {
    return (
        <Grid gap={0} sx={sx.picker} {...props}>
            <OrbitButton
                orbit={ORBITS.HIGH}
                onSelect={onSelect}
                selected={orbit === ORBITS.HIGH}
                disabled={disabled}
                label='Above tank'
            />
            <OrbitButton
                orbit={ORBITS.MID}
                onSelect={onSelect}
                selected={orbit === ORBITS.MID}
                disabled={disabled}
                label='Mid-tank'
            />
            <OrbitButton
                orbit={ORBITS.LOW}
                onSelect={onSelect}
                selected={orbit === ORBITS.LOW}
                disabled={disabled}
                label='Below tank'
            />
        </Grid>
    );
}

export default OrbitPicker;
