/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Box, Link } from 'theme-ui';
import { useState } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt as faExternalLink } from '@fortawesome/free-solid-svg-icons';

import MapcallWarning from './MapcallWarning';
import SortBar from '../components/SortBar';
import SearchSparkline from '../components/SearchSparkline';
import {
    toMapcallUrl,
    flightToDateString,
    getPressureZone,
    sortTanks,
    getLastFlight,
} from '../utils';
import { SORTS } from '../constants';

const sx = {
    page: {
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    appBar: {
        flex: 'none',
        zIndex: 2,
    },
    filterBar: {
        flex: 'none',
        zIndex: 1,
    },
    sortBar: {
        flex: 'none',
        mb: 1,
    },
    tanks: {
        flex: 'auto',
        px: 3,
        pb: 4,
        mb: 100,
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    thead: {
        '& th': {
            pb: 2,
            pr: 2,
            fontSize: 2,
            fontWeight: 'bold',
            textAlign: 'left',
            whiteSpace: 'nowrap',
        },
    },
    tbody: {
        '& tr': {
            cursor: 'pointer',
            '&:hover, &:focus, &:focus-within': {
                bg: 'primaryTint',
            },
            height: '3.675rem',
        },
        '& td': {
            pr: 2,
            fontSize: 2,
            whiteSpace: 'nowrap',
        },
    },
    sparkline: {
        width: '8rem',
    },
    name: {
        whiteSpace: 'normal',
    },
    mapcall: {
        color: 'primary',
        textDecoration: 'none',
        ':hover, tr:hover &': {
            textDecoration: 'underline',
        },
    },
};

function TankRow({ tank, onClick, ...props }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <tr
            {...props}
            tabIndex={0}
            onClick={onClick}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
        >
            <td>
                {tank.flights?.length > 0 && (
                    <SearchSparkline
                        tank={tank}
                        showAll={isHovered}
                        sx={sx.sparkline}
                    />
                )}
            </td>
            <td sx={sx.name}>{tank.facilityname}</td>
            <td>{tank.operatingcenter}</td>
            <td>{getPressureZone(tank)}</td>
            <td>{tank.pwsid}</td>
            <td>
                <MapcallWarning>
                    <Link
                        sx={sx.mapcall}
                        href={toMapcallUrl(tank)}
                        target='_blank'
                    >
                        {tank.mapcall_id}{' '}
                        {tank.mapcall_id && (
                            <Icon icon={faExternalLink} sx={sx.linkIcon} />
                        )}
                    </Link>
                </MapcallWarning>
            </td>
            <td>{flightToDateString(getLastFlight(tank)) || 'None'}</td>
        </tr>
    );
}

function SearchList({ tanks, onTankSelect }) {
    const [sort, setSort] = useState(SORTS.CONDITION);
    const [desc, setDesc] = useState(false);

    const sortedTanks = sortTanks(tanks, sort, desc);

    return (
        <Flex sx={sx.page}>
            <SortBar
                sx={sx.sortBar}
                tanks={tanks}
                onSort={setSort}
                sort={sort}
                desc={desc}
                setDesc={setDesc}
            />
            <Box sx={sx.tanks}>
                <table sx={sx.table}>
                    <thead sx={sx.thead}>
                        <tr>
                            <th>Condition</th>
                            <th>Name</th>
                            <th>Operating Center</th>
                            <th>Pressure Zone</th>
                            <th>PWSID</th>
                            <th>Mapcall</th>
                            <th>Last flight</th>
                        </tr>
                    </thead>
                    <tbody sx={sx.tbody}>
                        {sortedTanks?.map(tank => (
                            <TankRow
                                key={tank.id}
                                tank={tank}
                                onClick={() => onTankSelect(tank.id)}
                            />
                        ))}
                    </tbody>
                </table>
            </Box>
        </Flex>
    );
}

export default SearchList;
