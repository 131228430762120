/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import querystring from 'querystring';
import {
    jsx,
    Box,
    Flex,
    Heading,
    Card,
    IconButton,
    Label,
    Input,
    Button,
    Text,
    Image,
} from 'theme-ui';
import {
    Switch,
    Redirect,
    Route,
    useHistory,
    useRouteMatch,
    useParams,
    useLocation,
    matchPath,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLock } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Logo } from '../img/Logo.svg';
import oktaLogo from '../img/Logo_Okta_Blue_RGB.png';
import { APP_NAME, DEFAULT_ST } from '../constants';
import {
    Login,
    Register,
    ResetPassword,
    ConfirmResetPassword,
} from '../redux/auth.actions';
import { SetST } from '../redux/st.actions';
import { isSTValidAndEnabled, useQuery } from '../utils';

import STSelector from '../components/STSelector';

const sx = {
    page: {
        bg: 'primary',
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    logo: {
        mb: 5,
        flex: 'none',
        width: '4.8rem',
        color: '#fff',
    },
    heading: {
        mb: 5,
        flex: 'none',
        color: '#fff',
        fontSize: 6,
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    card: {
        flex: 'none',
        bg: 'background',
        width: '40rem',
        boxShadow: 4,
    },
    cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '4.8rem',
        px: '4.8rem',
    },
    cardHeading: {
        m: 0,
        fontSize: 3,
        lineHeight: 1,
    },
    cardBack: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4.8rem',
        height: '4.8rem',
        fontSize: '1.6rem',
    },
    cardBody: {
        pt: 4,
        pb: 5,
        px: 5,
    },
    label: {
        mb: 1,
    },
    input: {
        mb: 4,
        fontSize: '1.6rem',
    },
    stSelector: {
        width: '100%',
        py: 2,
        fontSize: '1.6rem',
    },
    submit: {
        width: '100%',
        mt: 2,
    },
    modeButtons: {
        mt: 6,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '40rem',
    },
    modeButton: {
        flex: 'none',
        color: 'white',
    },
    errors: {
        color: 'severe',
    },
    trustSymbol: {
        pt: 6,
        textAlign: 'center',
    },
    trustText: {
        color: 'iconGray',
    },
    oktaLogo: {
        py: 2,
        height: '5.6rem',
    },
};

function CardHeader({ title, onBack }) {
    return (
        <Flex as='header' sx={sx.cardHeader}>
            <IconButton onClick={onBack} sx={sx.cardBack} aria-label='Back'>
                <Icon icon={faArrowLeft} />
            </IconButton>
            <Heading as='h2' sx={sx.cardHeading}>
                {title}
            </Heading>
        </Flex>
    );
}

function ErrorsForInput({ errors, name }) {
    if (errors && errors[name]) {
        return errors[name].map(error => (
            <p sx={sx.errors} key={error}>
                {error}
            </p>
        ));
    }
    return null;
}

function SignInCard({ onSubmit, successMessage, error, STList, ST }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selectedST, setSelectedST] = useState(DEFAULT_ST);

    useEffect(() => {
        if (STList && ST) {
            const initialST = isSTValidAndEnabled(ST, STList) ? ST : DEFAULT_ST;
            setSelectedST(initialST);
        }
    }, [setSelectedST, ST, STList]);

    return (
        <Card sx={sx.card}>
            <Box sx={sx.cardBody}>
                <Box
                    as='form'
                    onSubmit={e => {
                        e.preventDefault();
                        onSubmit({ email, password, selectedST });
                    }}
                >
                    <Label htmlFor='email' sx={sx.label}>
                        Email
                    </Label>
                    <Input
                        required
                        id='email'
                        sx={sx.input}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Label htmlFor='password' sx={sx.label}>
                        Password
                    </Label>
                    <Input
                        required
                        id='password'
                        type='password'
                        sx={sx.input}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Label htmlFor='state' sx={sx.label}>
                        State
                    </Label>
                    <STSelector
                        sx={sx.stSelector}
                        selectedST={selectedST}
                        onSelectST={setSelectedST}
                        STList={STList}
                    />
                    <p sx={sx.errors}>{error}</p>
                    <p>{successMessage}</p>
                    <Button variant='cta' sx={sx.submit} type='submit'>
                        Sign in
                    </Button>
                    <Button
                        sx={sx.submit}
                        type='button'
                        onClick={() =>
                            window.location.assign(
                                window.ENVIRONMENT.OKTA_LOGIN_URL
                            )
                        }
                    >
                        Sign in with Okta
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

function ResetPasswordCard({ onBack, onSubmit, successMessage, errors }) {
    const [email, setEmail] = useState('');

    // Once there is a success message, navigate to login page.
    useEffect(() => {
        if (successMessage.length) {
            onBack();
        }
    }, [successMessage, onBack]);

    return (
        <Card sx={sx.card}>
            <CardHeader onBack={onBack} title='Reset password' />
            <Box sx={sx.cardBody}>
                <Box
                    as='form'
                    onSubmit={e => {
                        e.preventDefault();
                        onSubmit({ email });
                    }}
                >
                    <Label htmlFor='email' sx={sx.label}>
                        Email
                    </Label>
                    <ErrorsForInput errors={errors} name='email' />
                    <Input
                        id='email'
                        sx={sx.input}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        required
                    />
                    <Button variant='cta' sx={sx.submit} type='submit'>
                        Reset password
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

function ConfirmResetPasswordCard({
    onBack,
    onSubmit,
    errors,
    successMessage,
}) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { search } = useLocation();
    const params = useParams();

    const qsToParse = search.startsWith('?') ? search.slice(1) : search;
    const { token } = querystring.parse(qsToParse);

    // Once there is a success message, navigate to login page.
    useEffect(() => {
        if (successMessage.length) {
            onBack();
        }
    }, [successMessage, onBack]);

    return (
        <Card sx={sx.card}>
            <CardHeader onBack={onBack} title='Reset password' />
            <Box sx={sx.cardBody}>
                <Box
                    as='form'
                    onSubmit={e => {
                        e.preventDefault();
                        onSubmit({
                            uid: params.uid,
                            token,
                            newPassword: password,
                            newPasswordConfirmation: confirmPassword,
                        });
                    }}
                >
                    <Label htmlFor='password1' sx={sx.label}>
                        New Password
                    </Label>
                    <ErrorsForInput errors={errors} name='new_password1' />
                    <Input
                        required
                        id='password1'
                        type='password'
                        sx={sx.input}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Label htmlFor='password2' sx={sx.label}>
                        Confirm New Password
                    </Label>
                    <ErrorsForInput errors={errors} name='new_password2' />
                    <Input
                        required
                        id='password2'
                        type='password'
                        sx={sx.input}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <ErrorsForInput errors={errors} name='token' />
                    <Button variant='cta' sx={sx.submit} type='submit'>
                        Reset password
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

function CreateAccountCard({ onBack, onSubmit, errors, successMessage }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Once there is a success message, navigate to login page.
    useEffect(() => {
        if (successMessage.length) {
            onBack();
        }
    }, [successMessage, onBack]);

    return (
        <Card sx={sx.card}>
            <CardHeader onBack={onBack} title='Create account' />
            <Box sx={sx.cardBody}>
                <Box
                    as='form'
                    onSubmit={e => {
                        e.preventDefault();
                        onSubmit({ email, password, name });
                    }}
                >
                    <Label htmlFor='name' sx={sx.label}>
                        Name
                    </Label>
                    <Input
                        required
                        id='name'
                        sx={sx.input}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <Label htmlFor='email' sx={sx.label}>
                        Email
                    </Label>
                    <ErrorsForInput errors={errors} name='email' />
                    <Input
                        required
                        id='email'
                        sx={sx.input}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Label htmlFor='password' sx={sx.label}>
                        Password
                    </Label>
                    <ErrorsForInput errors={errors} name='password' />
                    <Input
                        required
                        id='password'
                        type='password'
                        sx={sx.input}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button variant='cta' sx={sx.submit} type='submit'>
                        Create account
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

function SSOCard() {
    const query = useQuery();

    const error = query.get('error');

    return (
        <Card sx={sx.card}>
            <Box sx={sx.cardBody}>
                <Box
                    as='form'
                    onSubmit={e => {
                        e.preventDefault();
                        window.location.assign(
                            window.ENVIRONMENT.OKTA_LOGIN_URL
                        );
                    }}
                >
                    <Button variant='cta' sx={sx.submit} type='submit'>
                        Sign in with Okta
                    </Button>
                    {error && (
                        <p sx={sx.errors}>
                            There was an error logging you in. Please ensure
                            your account has the permission to use Sky View.
                        </p>
                    )}
                    <Box sx={sx.trustSymbol}>
                        <Text sx={sx.trustText}>
                            <Icon icon={faLock} /> Secure Sign-in Partner
                        </Text>
                        <Image src={oktaLogo} sx={sx.oktaLogo} />
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}

function AuthPage({
    onLogin,
    onRegister,
    isAuthenticated,
    error,
    formErrors,
    successMessage,
    STList,
    ST,
    onResetPassword,
    onConfirmResetPassword,
    onSTSelect,
    ...rest
}) {
    // The Nesting example recommends using url and path for their separate
    // purposes, although in our case they always have the same value.
    // https://reacttraining.com/react-router/web/example/nesting
    const { url, path } = useRouteMatch();
    const history = useHistory();

    const { from } = history.location.state || {
        from: { pathname: '/' },
    };

    if (isAuthenticated) {
        // If the user selects a different state than they navigated to while
        // unauthenticated, redirect them to the selected state's map page
        const path = matchPath(from, { path: '/:ST/' });
        const toPath = path?.params?.ST === ST ? from : `/${ST}/`;
        return <Redirect to={toPath} />;
    }

    const loginAndUpdateRouteST = ({ selectedST, ...props }) => {
        onSTSelect(selectedST);
        onLogin(props);
    };

    return (
        <Flex sx={sx.page}>
            <Logo sx={sx.logo} />
            <Heading as='h1' sx={sx.heading}>
                {APP_NAME}
            </Heading>
            <Switch>
                <Route path={`${path}/reset`}>
                    <ResetPasswordCard
                        onBack={() => history.push(`${url}/login`)}
                        onSubmit={onResetPassword}
                        successMessage={successMessage}
                        errors={formErrors}
                    />
                </Route>
                <Route path={`${path}/create`}>
                    <CreateAccountCard
                        onSubmit={onRegister}
                        errors={formErrors}
                        successMessage={successMessage}
                        onBack={() => history.push(`${url}/login`)}
                    />
                </Route>
                <Route path={`${path}/resetpassword/:uid`}>
                    <ConfirmResetPasswordCard
                        onBack={() => history.push(`${url}/login`)}
                        errors={formErrors}
                        successMessage={successMessage}
                        onSubmit={onConfirmResetPassword}
                    />
                </Route>
                <Route path={`${path}/login/sso`}>
                    <SSOCard />
                </Route>
                <Route path={`${path}/login/email`}>
                    <SignInCard
                        onSubmit={loginAndUpdateRouteST}
                        error={error}
                        successMessage={successMessage}
                        STList={STList}
                        ST={ST}
                    />
                    <Flex sx={sx.modeButtons}>
                        <Button
                            variant='buttons.text'
                            sx={sx.modeButton}
                            onClick={() => history.push(`${url}/reset`)}
                        >
                            Reset password
                        </Button>
                        <Button
                            variant='buttons.text'
                            sx={sx.modeButton}
                            onClick={() => history.push(`${url}/create`)}
                        >
                            Need an account?
                        </Button>
                    </Flex>
                </Route>
                <Redirect from={path} to={`${path}/login/sso`} />
            </Switch>
        </Flex>
    );
}

const mapStateToProps = ({ auth, st }) => ({
    isAuthenticated: !!auth.user,
    error: auth.error,
    formErrors: auth.formErrors,
    successMessage: auth.successMessage,
    STList: st.STList,
    ST: st.ST,
});

const mapDispatchToProps = dispatch => {
    return {
        onLogin: ({ email, password }) => {
            dispatch(Login({ email, password }));
        },
        onRegister: ({ name, email, password }) =>
            dispatch(Register({ name, email, password })),
        onResetPassword: ({ email }) => dispatch(ResetPassword({ email })),
        onConfirmResetPassword: passwordForm =>
            dispatch(ConfirmResetPassword(passwordForm)),
        onSTSelect: ST => dispatch(SetST(ST)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
