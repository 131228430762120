import produce from 'immer';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
    JOB_STATUS,
    CONDITION_COLORS,
    NUMERIC_CONDITIONS,
    SORTS,
    CONDITIONS,
    NULL_UI_VALUE,
} from './constants';

// via https://gist.github.com/jlbruno/1535691/db35b4f3af3dcbb42babc01541410f291a8e8fac
export function ordinalSuffix(n) {
    let s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
}

export function conditionValueToLabel(v) {
    if (v < 0 || v > 1) return null;
    if (v < 0.2) return 'severe';
    if (v < 0.4) return 'poor';
    if (v < 0.6) return 'fair';
    if (v < 0.8) return 'good';
    return 'excellent';
}

export function conditionValueToColor(v) {
    const label = conditionValueToLabel(v);
    if (label) return CONDITION_COLORS[label];
    return null;
}

export function toMapcallUrl(tank) {
    return `https://mapcall.awapps.com/Modules/mvc/Equipment/Show/${tank.mapcall_id}`;
}

export function toWorkorderUrl(flight) {
    return `https://mapcall.awapps.com/Modules/mvc/Production/ProductionWorkOrder/Show/${flight.workorder_id}`;
}

export function unique(array) {
    return array.filter((v, i, a) => a.indexOf(v) === i);
}

export function getPressureZone(t) {
    return `${t.operatingcenter || NULL_UI_VALUE} - ${
        t.pressurezonename || NULL_UI_VALUE
    } - HGL: ${t.pressurezonehgl || NULL_UI_VALUE} ft`;
}

// Doing `new Date('2020-08-01')` results in July 31, 2020, because it defaults
// to using UTC timezones. By using `new Date(2020, 7, 1)` we get Aug 1, 2020,
// where the month is a 0-indexed value, uses the system's timezone.
export function YMDToDate(ymd) {
    const year = parseInt(ymd.substring(0, 4));
    const month = parseInt(ymd.substring(5, 7)) - 1;
    const day = parseInt(ymd.substring(8));

    return new Date(year, month, day);
}

export function dateToYMD(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
        month = `0${month}`;
    }

    if (day < 10) {
        day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
}

export function formatQuery(str) {
    return str ? str.toLowerCase().trim() : '';
}

export function applyTankFilters(tanks, filters) {
    const {
        opCenter,
        condition,
        pressureZone,
        pwsid,
        query,
        flightDates,
    } = filters;
    return tanks?.filter(t => {
        if (
            !!opCenter &&
            (opCenter === NULL_UI_VALUE
                ? !!t.operatingcenter
                : opCenter !== t.operatingcenter)
        )
            return false;
        if (!!condition && condition !== t.condition) return false;
        if (!!pressureZone && pressureZone !== getPressureZone(t)) return false;
        if (
            !!pwsid &&
            (pwsid === NULL_UI_VALUE ? !!t.pwsid : pwsid !== t.pwsid)
        )
            return false;
        if (!!query) {
            if (!formatQuery(t.facilityname).includes(formatQuery(query))) {
                return false;
            }
        }
        if (!!flightDates) {
            if (flightDates.indexOf('--') === -1) {
                // Single flight date specified, look for exact match
                if (!t.flights.map(f => f.flight_date).includes(flightDates)) {
                    return false;
                }
            } else {
                // Flight range specified. Exclude those outside.
                const from = flightDates.substring(0, 10);
                const to = flightDates.substring(12);

                if (
                    !t.flights.some(
                        f => from <= f.flight_date && f.flight_date <= to
                    )
                ) {
                    return false;
                }
            }
        }
        return true;
    });
}

export function removeEmpty(obj) {
    const filteredObject = {};

    Object.keys(obj).forEach(k => {
        if (!!obj[k]) {
            filteredObject[k] = obj[k];
        }
    });

    return filteredObject;
}

export function logError(error) {
    let message = error.info || error.message || 'Error';
    if (message.detail) {
        message = message.detail;
    }
    // Report error to Rollbar if available
    if (window.Rollbar) {
        window.Rollbar.error(message, error);
    } else {
        console.error(message, error);
    }
}

export const set = field =>
    produce((state, payload) => {
        state[field] = payload;
    });

// Convert flight to date string like 'March 1, 2020'
// Need to add the time to get it to work with Eastern Time Zone, else it
// defaults to UTC and displays the day before after converting to Eastern.
export const flightToDateString = flight =>
    flight &&
    new Date(`${flight.flight_date}T00:00:00`).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });

// Stops user from navigating away from page without confirming explicitly
const confirmNavigate = e => {
    // Cancel event as stated by the standard
    e.preventDefault();

    // Chrome requires returnValue to be set
    e.returnValue = '';
};

export const discourageUserNavigation = () =>
    window.addEventListener('beforeunload', confirmNavigate);
export const allowUserNavigation = () =>
    window.removeEventListener('beforeunload', confirmNavigate);

// Chart Utilities
export const isFlightProcessing = flight =>
    flight.processing === JOB_STATUS.STARTED;

export const getFlightCondition = flight =>
    flight.condition_override || flight.condition;

export const getXAxis = flight => YMDToDate(flight.flight_date);

export const getYAxis = tank => flight => {
    const condition = getFlightCondition(flight);

    if (condition !== 'unknown') {
        return NUMERIC_CONDITIONS[condition];
    }

    // If the condition is unknown, find the most recent non-unknown flight
    // before this and use its numeric value
    if (tank.flights.length > 1 && tank.flights.indexOf(flight) > 0) {
        for (let i = tank.flights.indexOf(flight) - 1; i >= 0; i--) {
            const i_condition = getFlightCondition(tank.flights[i]);
            if (i_condition !== 'unknown') {
                return NUMERIC_CONDITIONS[i_condition];
            }
        }
    }

    // Either single unknown flight or all flights have unknown conditions
    return 0.5;
};

const sortBy = (arr, comparer) =>
    arr
        .slice()
        .sort((a, b) =>
            comparer(a) > comparer(b) ? 1 : comparer(b) > comparer(a) ? -1 : 0
        );

const getConditionValue = flight =>
    flight.condition_override
        ? CONDITIONS.indexOf(flight.condition_override)
        : CONDITIONS.indexOf(flight.condition);

export const getLastFlight = tank =>
    tank.flights && tank.flights[tank.flights.length - 1];

export const sortTanks = (tanks, sort, desc) => {
    if (!tanks?.length) return [];

    let sortedTanks = tanks;
    if (sort === SORTS.NAME) {
        sortedTanks = sortBy(tanks, t => t.facilityname);
    }
    if (sort === SORTS.PWSID) {
        sortedTanks = sortBy(tanks, t => t.pwsid);
    }
    if (sort === SORTS.OPCENTER) {
        sortedTanks = sortBy(tanks, t => t.operatingcenter);
    }
    if (sort === SORTS.FLIGHT) {
        sortedTanks = sortBy(tanks, t => getLastFlight(t)?.flight_date || '');
    }
    if (sort === SORTS.CONDITION) {
        let groupedByFlights = tanks.reduce(
            (acc, curr) => {
                if (curr.flights?.length) {
                    return { ...acc, flights: [...acc.flights, curr] };
                } else {
                    return { ...acc, noFlights: [...acc.noFlights, curr] };
                }
            },
            { flights: [], noFlights: [] }
        );
        const flights = sortBy(groupedByFlights.flights, getConditionValue);
        sortedTanks = [...flights, ...groupedByFlights.noFlights];
    }

    if (desc) {
        sortedTanks = sortedTanks.reverse();
    }

    return sortedTanks;
};

// Esri world geocoder suggestions. Limit results to country code USA, which
// includes US territories. Applies a bounding box to further limit search to
// New Jersey.
export const getSuggestUrl = text =>
    `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${text}&searchExtent=-75.559614,38.928519,-73.893979,41.357423&countryCode=usa&f=json`;
export const getGeocodeUrl = (address, magicKey) =>
    `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine=${address}&magicKey=${magicKey}&countryCode=usa&f=json`;

export const getValidST = (ST, states) => {
    return Array.isArray(states) ? states?.find(st => st.id === ST) : undefined;
};

export const isSTValidAndEnabled = (ST, states) => {
    const STDetail = getValidST(ST, states);
    return STDetail?.enabled;
};

const PILOT = 'PILOT';
const STAFF = 'STAFF';

export const userRolesForST = (user, ST) => {
    return user.roles.filter(r => r.state === ST);
};

export const isPilotForST = (user, ST) => {
    return userRolesForST(user, ST).find(r => r.role === PILOT);
};

export const isAdminForST = (user, ST) => {
    return (
        user.is_superadmin ||
        userRolesForST(user, ST).find(r => r.role === STAFF)
    );
};

export const canUserViewST = (user, ST, states) => {
    if (isSTValidAndEnabled(ST, states)) return true;

    if (isAdminForST(user, ST)) return true;

    return false;
};

// via https://v5.reactrouter.com/web/example/query-parameters
export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};
