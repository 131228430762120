/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Card } from 'theme-ui';
import {
    Children,
    Fragment,
    cloneElement,
    isValidElement,
    useState,
} from 'react';
import { node } from 'prop-types';

const sx = {
    hoverCardWrapper: {
        position: 'relative',
        top: 2,
    },
    hoverCard: {
        position: 'absolute',
        maxWidth: '23rem',
        minWidth: '18rem',
        bg: 'background',
        boxShadow: 2,
        zIndex: 1,
        p: 2,
        whiteSpace: 'normal',
        fontWeight: 'normal',
    },
};

function MapcallWarning({ leftAlign = false, children }) {
    const [showHoverCard, setShowHoverCard] = useState(false);

    return (
        <Fragment>
            {Children.map(children, child =>
                isValidElement(child)
                    ? cloneElement(child, {
                          onMouseOver: () => setShowHoverCard(true),
                          onMouseOut: () => setShowHoverCard(false),
                      })
                    : child
            )}
            {showHoverCard && (
                <Box sx={sx.hoverCardWrapper}>
                    <Card
                        sx={
                            leftAlign
                                ? { ...sx.hoverCard, left: 1 }
                                : { ...sx.hoverCard, right: 1 }
                        }
                    >
                        This link requires both VPN and Mapcall State privileges
                        to view the Mapcall Equipment.
                    </Card>
                </Box>
            )}
        </Fragment>
    );
}

MapcallWarning.propTypes = {
    children: node.isRequired,
};

export default MapcallWarning;
