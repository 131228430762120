/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text, Spinner } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const sx = {
    container: {
        flexDirection: 'column',
        alignItems: 'end',
    },
    condition: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    swatch: {
        mr: 1,
        fontSize: 4,
    },
    spinner: {
        mr: 1,
        color: 'text',
        size: '2rem',
        strokeWidth: 3,
    },
    desc: {
        fontWeight: 'bold',
        lineHeight: 1.8,
        '&::first-letter': {
            textTransform: 'capitalize',
        },
    },
    conditionedAt: {
        fontSize: 1,
        color: 'textGray',
    },
};

function Condition({
    condition = 'unknown',
    conditionedAt = null,
    isUploading,
    isProcessing,
    ...props
}) {
    let desc, icon;
    if (isUploading) {
        desc = 'Uploading…';
        icon = <Spinner sx={sx.spinner} />;
    } else if (isProcessing) {
        desc = 'Processing condition…';
        icon = <Spinner sx={sx.spinner} />;
    } else if (condition === 'unknown') {
        desc = `Condition unknown`;
        icon = <Icon icon={faCircle} sx={{ ...sx.swatch, color: condition }} />;
    } else {
        desc = `${condition} condition`;
        icon = <Icon icon={faCircle} sx={{ ...sx.swatch, color: condition }} />;
    }

    return (
        <Flex sx={sx.container}>
            <Flex sx={sx.condition} {...props}>
                {icon}
                <Text sx={sx.desc}>{desc}</Text>
            </Flex>
            {conditionedAt && (
                <Text sx={sx.conditionedAt}>
                    Updated{' '}
                    {new Date(`${conditionedAt}`).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </Text>
            )}
        </Flex>
    );
}

export default Condition;
