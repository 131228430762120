/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

import FlightHeader from './FlightHeader';
import FlightFooter from './FlightFooter';
import OrbitPicker from './OrbitPicker';
import PhotoGrid from './PhotoGrid';

const sx = {
    flight: {
        borderWidth: '1px 0 0 0',
        borderStyle: 'solid',
        borderColor: 'backgroundGray',
        py: 1,
        px: 2,
    },
    header: {
        mb: 2,
    },
    orbit: {
        mb: 2,
    },
    photos: {
        my: 1,
    },
    footer: {
        my: 1,
    },
};

function DetailFlight({
    ST,
    tank,
    flight,
    orbit,
    onOrbitChange,
    image,
    onImageChange,
    ...props
}) {
    return (
        <Box sx={sx.flight} {...props}>
            {flight && (
                <FlightHeader
                    sx={sx.header}
                    ST={ST}
                    tank={tank}
                    flight={flight}
                />
            )}
            <OrbitPicker
                orbit={orbit}
                onSelect={onOrbitChange}
                sx={sx.orbit}
                disabled={!flight}
            />
            <PhotoGrid
                flight={flight}
                orbit={orbit}
                image={image}
                onSelect={onImageChange}
                disabled={!flight}
                sx={sx.photos}
            />
            {flight && (
                <FlightFooter
                    ST={ST}
                    tank={tank}
                    flight={flight}
                    sx={sx.footer}
                />
            )}
        </Box>
    );
}

export default DetailFlight;
