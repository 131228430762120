/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import {
    VictoryChart,
    VictoryAxis,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryLabel,
} from 'victory';
import { format } from 'date-fns';

import { CONDITION_COLORS, JOB_STATUS } from '../constants';
import {
    dateToYMD,
    YMDToDate,
    isFlightProcessing,
    getFlightCondition,
    getXAxis,
    getYAxis,
} from '../utils';

const sx = {
    container: {
        p: 2,
    },
};

function PointLabel(props) {
    return props.datum.processing === JOB_STATUS.STARTED ? (
        <VictoryLabel
            {...props}
            dy={8}
            style={{
                fontFamily: 'inherit',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
            }}
        />
    ) : (
        <VictoryTooltip
            {...props}
            cornerRadius={4}
            pointerLength={0}
            flyoutStyle={{
                strokeWidth: 0,
                fill: '#ffffffaa',
            }}
        />
    );
}

const noOp = () => {};

function DetailChart({
    ST,
    tank,
    selectedFlight,
    height = 120,
    chartProps = {},
    axisProps = {},
    onMouseOver = noOp,
    renavigate = false,
    ...props
}) {
    const history = useHistory();

    let selectedDate;
    if (selectedFlight) {
        selectedDate = YMDToDate(selectedFlight.flight_date);
    }

    // If renavigate is set, then always navigate to selected flight.
    // Otherwise, only navigate if different from currently selected flight.
    const onFlightDateSelect = flight_date =>
        (renavigate ||
            !selectedDate ||
            flight_date !== selectedFlight.flight_date) &&
        history.push(`/${ST}/tank/${tank.id}/flight/${flight_date}`);

    function isSelectedDate(d) {
        return selectedDate && d.getTime() === selectedDate.getTime();
    }

    const isSelectedFlight = flight =>
        flight &&
        selectedFlight &&
        flight.flight_date === selectedFlight.flight_date;

    return (
        <Box sx={sx.container} {...props}>
            <VictoryChart
                domain={{ y: [0, 1] }}
                height={120}
                padding={{ top: 10, right: 20, bottom: 44, left: 20 }}
                {...chartProps}
            >
                <VictoryAxis
                    style={{
                        axis: { stroke: '#ccc' },
                        tickLabels: {
                            fill: ({ tick }) =>
                                isSelectedDate(tick) ? '#000' : '#444',
                            fontFamily: 'inherit',
                            fontSize: '13px',
                            fontWeight: ({ tick }) =>
                                isSelectedDate(tick) ? 'bold' : 'normal',
                            cursor: 'pointer',
                        },
                    }}
                    tickValues={tank.flights.map(f => YMDToDate(f.flight_date))}
                    tickFormat={t => format(t, 'MM/yy')}
                    offsetY={24}
                    events={[
                        {
                            target: 'tickLabels',
                            eventHandlers: {
                                onClick: (e, { datum }) =>
                                    onFlightDateSelect(dateToYMD(datum)),
                            },
                        },
                    ]}
                    {...axisProps}
                />
                <VictoryLine
                    data={tank.flights}
                    style={{ data: { stroke: '#ccc' } }}
                    x={getXAxis}
                    y={getYAxis(tank)}
                />
                <VictoryScatter
                    data={tank.flights}
                    x={getXAxis}
                    y={getYAxis(tank)}
                    size={({ datum }) =>
                        isSelectedFlight(datum) || isFlightProcessing(datum)
                            ? 11
                            : 5
                    }
                    labels={({ datum }) =>
                        isFlightProcessing(datum)
                            ? '?'
                            : getFlightCondition(datum)
                    }
                    labelComponent={<PointLabel />}
                    events={[
                        {
                            target: 'data',
                            eventHandlers: {
                                onClick: (e, { datum }) => {
                                    onFlightDateSelect(datum.flight_date);
                                },
                                onMouseOver: (e, { datum }) => {
                                    onMouseOver(datum);
                                },
                                onMouseOut: () => onMouseOver(null),
                            },
                        },
                    ]}
                    style={{
                        data: {
                            strokeWidth: ({ datum }) => {
                                if (isSelectedFlight(datum)) return 4;
                                if (isFlightProcessing(datum)) return 2;
                                return 1;
                            },
                            stroke: ({ datum }) => {
                                if (isFlightProcessing(datum)) return '#222';
                                if (isSelectedFlight(datum)) return '#fff';
                                return '#ccc';
                            },
                            fill: ({ datum }) =>
                                isFlightProcessing(datum)
                                    ? '#fff'
                                    : CONDITION_COLORS[
                                          getFlightCondition(datum)
                                      ],
                            cursor: 'pointer',
                        },
                    }}
                />
            </VictoryChart>
        </Box>
    );
}

export default DetailChart;
