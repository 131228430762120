/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Flex, Heading, Card, Button, Text } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from '../img/Logo.svg';
import { APP_NAME } from '../constants';

const sx = {
    page: {
        bg: 'primary',
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    logo: {
        mb: 5,
        flex: 'none',
        width: '4.8rem',
        color: '#fff',
    },
    heading: {
        mb: 5,
        flex: 'none',
        color: '#fff',
        fontSize: 6,
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    card: {
        flex: 'none',
        bg: 'background',
        width: '40rem',
        boxShadow: 4,
    },
    cardBody: {
        pt: 4,
        pb: 5,
        px: 5,
    },
    submit: {
        width: '100%',
        mt: 2,
    },
};

function ConfirmEmailPage() {
    const history = useHistory();

    return (
        <Flex sx={sx.page}>
            <Logo sx={sx.logo} />
            <Heading as='h1' sx={sx.heading}>
                {APP_NAME}
            </Heading>
            <Card sx={sx.card}>
                <Box sx={sx.cardBody}>
                    <Text
                        sx={{
                            fontSize: 25,
                            fontWeight: 'bold',
                        }}
                    >
                        Your email has been verified.
                    </Text>
                    <Button
                        variant='cta'
                        sx={sx.submit}
                        onClick={() => history.replace('/')}
                    >
                        Continue
                    </Button>
                </Box>
            </Card>
        </Flex>
    );
}

export default ConfirmEmailPage;
