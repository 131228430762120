/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button, Flex } from 'theme-ui';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faMap, faTh, faThList } from '@fortawesome/free-solid-svg-icons';

const sx = {
    tabs: {
        flexDirection: 'row',
        alignItems: 'stretch',
    },
    tab: {
        mx: 2,
        py: '0.5rem',
        flex: 'none',
        fontSize: 3,
        color: 'white',
        borderRadius: 0,
        borderBottom: '2px solid transparent',
        opacity: 0.8,
        '&:hover': {
            textDecoration: 'none',
            borderBottomColor: 'white',
        },
    },
    active: {
        opacity: 1,
        borderBottomColor: 'white',
    },
    icon: {
        mr: 1,
    },
};

function Tab({ text, icon, active, onClick }) {
    const tabSx = active ? { ...sx.tab, ...sx.active } : sx.tab;
    return (
        <Button variant='text' sx={tabSx} aria-label={text} onClick={onClick}>
            <Icon icon={icon} sx={sx.icon} /> {text}
        </Button>
    );
}

function SearchTabs({ active, onChange, ...props }) {
    const isActive = key => active.substring(4) === key;

    return (
        <Flex sx={sx.tabs} {...props}>
            <Tab
                text='Map'
                icon={faMap}
                active={isActive('map')}
                onClick={() => onChange('map')}
            />
            <Tab
                text='Grid'
                icon={faTh}
                active={isActive('grid')}
                onClick={() => onChange('grid')}
            />
            <Tab
                text='List'
                icon={faThList}
                active={isActive('list')}
                onClick={() => onChange('list')}
            />
        </Flex>
    );
}

export default SearchTabs;
