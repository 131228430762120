const theme = {
    space: [
        0,
        '0.8rem',
        '1.6rem',
        '2.4rem',
        '3.2rem',
        '4rem',
        '4.8rem',
        '5.6rem',
        '6.4rem',
    ],
    fonts: {
        body:
            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [
        '1.1rem',
        '1.2rem',
        '1.3rem',
        '1.4rem',
        '1.6rem',
        '1.8rem',
        '2rem',
        '2.8rem',
        '3.4rem',
    ],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    colors: {
        text: '#000',
        textGray: '#565656',
        background: '#fff',
        backgroundGray: '#e7e7e7',
        iconSecondary: '#a9d0e6',
        iconGray: '#888',
        divider: '#d7d7d7',
        disabled: '#ccc',
        primary: '#00517f',
        primaryTint: '#f1f7fb',
        secondary: '#e9f0f6',
        accent: '#c51746',
        muted: '#f6f6f6',
        scrim: 'rgba(0, 0, 0, 0.2)',
        excellent: '#8ec138',
        good: '#c8cf27',
        fair: '#fedb00',
        poor: '#d88300',
        severe: '#ab1d00',
        unknown: '#a7a7a7',
    },
    shadows: [
        'none',
        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',
        '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
        '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)',
    ],
    text: {
        heading: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
        },
    },
    buttons: {
        button: {
            m: 0,
            px: 3,
            py: 2,
            font: 'inherit',
            border: 0,
            borderRadius: '2px',
            cursor: 'pointer',
        },
        secondaryCta: {
            variant: 'buttons.button',
            bg: 'iconGray',
            color: '#fff',
            fontSize: 4,
            fontWeight: 'bold',
        },
        cta: {
            variant: 'buttons.button',
            bg: 'accent',
            color: '#fff',
            fontSize: 4,
            fontWeight: 'bold',
        },
        disabled: {
            variant: 'buttons.cta',
            bg: 'muted',
            color: 'textGray',
            fontSize: 4,
            fontWeight: 'bold',
        },
        text: {
            variant: 'buttons.button',
            p: 0,
            bg: 'transparent',
            color: 'primary',
            ':hover': {
                textDecoration: 'underline',
            },
        },
        icon: {
            p: 0,
            cursor: 'pointer',
        },
        close: {
            p: 0,
            cursor: 'pointer',
        },
    },
    forms: {
        label: {
            fontSize: 4,
            fontWeight: 'bold',
        },
        input: {
            bg: 'muted',
            borderRadius: 0,
            borderColor: '#d3d3d3',
        },
        select: {
            variant: 'forms.input',
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            fontSize: 3,
            position: 'relative',
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            m: 0,
            p: 0,
            overflow: 'hidden',
        },
    },
};

export default theme;
