import theme from './theme';

export const APP_NAME = 'Sky View';

export const DEFAULT_ST = 'NJ';

export const MARKER_SHADOW = 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))';
export const CARD_SHADOW = 'drop-shadow(0 5px 5px rgba(0, 0, 0, 0.2))';

export const ORBITS = Object.freeze({
    LOW: 'low',
    MID: 'mid',
    HIGH: 'high',
    NADIR: 'nadir',
});

export const JOB_STATUS = Object.freeze({
    STARTED: 'started',
    DONE: 'done',
    FAILED: 'failed',
});

// Similar to api.serializers.DIRECTIONS, but left-to-right and top-to-bottom
export const BEARINGS = ['nw', 'n', 'ne', 'w', 'nadir', 'e', 'sw', 's', 'se'];

// Does not include nadir
export const BEARING_ANGLES = Object.freeze({
    nw: 315,
    n: 0,
    ne: 45,
    e: 90,
    se: 135,
    s: 180,
    sw: 225,
    w: 270,
});

export const CONDITIONS = [
    'severe',
    'poor',
    'fair',
    'good',
    'excellent',
    'unknown',
];

export const CONDITION_COLORS = Object.freeze({
    severe: theme.colors.severe,
    poor: theme.colors.poor,
    fair: theme.colors.fair,
    good: theme.colors.good,
    excellent: theme.colors.excellent,
    unknown: theme.colors.unknown,
});

export const NUMERIC_CONDITIONS = Object.freeze({
    excellent: 0.9,
    good: 0.7,
    fair: 0.5,
    poor: 0.3,
    severe: 0.1,
});

export const URLS = {
    // React app routes do not have trailing slash
    homepage: ST => `/${ST}`,
    // API routes
    login: () => '/api/auth/login/',
    logout: () => '/api/auth/logout/',
    register: () => '/api/auth/register/',
    resetPassword: () => '/api/rest-auth/password/reset/',
    confirmResetPassword: () => '/api/rest-auth/password/reset/confirm/',
    tanks: ST => `/api/${ST}/tanks/`,
    tank: (ST, tankId) => `/api/${ST}/tank/${tankId}/`,
    flight: (ST, tankId, flightDate) =>
        `/api/${ST}/tank/${tankId}/flight/${flightDate}/`,
    reconditionAllFlights: ST => `/api/${ST}/recondition_all_flights/`,
    fetchResultsForAllTanks: ST => `/api/${ST}/fetch_results_for_all_tanks/`,
    states: () => '/api/states/',
    state: ST => `/api/state/${ST}/`,
};

export const SORTS = {
    CONDITION: 'condition',
    FLIGHT: 'flight',
    NAME: 'name',
    OPCENTER: 'opcenter',
    PWSID: 'pwsid',
};

export const NULL_UI_VALUE = 'None';
